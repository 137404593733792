import React from 'react';
// Chakra imports
import { Box, Grid, Heading, Link, List, ListItem, Text, useColorModeValue } from '@chakra-ui/react';

const PrivacyPolicy: React.FC = () => {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.500";
  const termsOfServiceLink = "https://www.smartdatawealth.com/terms-of-service"; // Replace with your actual link to Terms of Service

  return (
    <Box p={{ base: 5, md: 8 }} color={textColor}>
      <Heading textAlign='center' mt={4}>Privacy Policy</Heading>
      <Text mt={4} color={textColorSecondary}>
        Last updated: 6/3/24
      </Text>
      <Heading size='md' mt={4}>INTRODUCTION</Heading>
      <Text mt={2}>
        At Clarifees LLC dba Smartdata, (“<b>Clarifees LLC dba Smartdata</b>,” or “<b>we</b>,” or “<b>us</b>”),
        we respect your privacy and are committed to protecting it through our compliance
        with this Privacy Policy (“<b>Policy</b>”).
      </Text>
      <Text mt={4}>
        This Policy describes what types of information Clarifees LLC dba Smartdata may collect
        from you or that you may provide when you (i) visit the website <Link href='https://www.smartdatawealth.com'
                                                                              target='_blank'
                                                                              color='blue.500'>www.smartdatawealth.com</Link>,
        including any affiliated sub-domains and affiliated websites (the “<b>Site</b>”); and (ii) access
        and use the services, including any mobile application made available to access and use
        these services (the “<b>Services</b>”). In addition this Policy, outlines our practices for
        collecting, using, maintain, protecting and disclosing that information.
      </Text>

      <Text mt={4}>
        This Policy applies to information we collect:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          On this Site and during your use of the Services;
        </ListItem>
        <ListItem>
          In email, text, and other electronic messages between you and this Site;
        </ListItem>
        <ListItem>
          Through mobile and desktop applications you download from this Site, which provide dedicated
          non-browser-based interactions between you and this Site;
        </ListItem>
        <ListItem>
          When you interact with our advertising and applications on third-party websites and services, if those
          applications or advertising include links to this Policy.
        </ListItem>
      </List>

      <Heading size='md' mt={4}>CONSENT</Heading>
      <Text mt={2}>
        By accessing the Site or using the Services, you are consenting to the
        information collection, use, and disclosure practices described in this Policy.
        Your use of the Site and Services is also governed by the Clarifees LLC dba
        Smartdata’ Terms of Service, located at
        <Link href="https://www.smartdatawealth.com/auth/privacy-policy" isExternal>
          https://www.smartdatawealth.com/auth/privacy-policy
        </Link>
        , which are expressly incorporated into this Policy by this reference. If you do
        not agree with this Policy, please do not access the Site or use the Services.
      </Text>

      <Heading size='md' mt={4}>UPDATES</Heading>
      <Text mt={2}>
        Clarifees LLC dba Smartdata reserves the right to make changes to this Policy at any time and will post any
        revised
        Policy on this page. The revised Policy will be effective when posted. You will know when we have changed the
        Policy when you see a new updated date at the top of this Policy. Unless required by applicable law, Clarifees
        LLC
        dba Smartdata will not notify any users of the Site or Services of any such changes by email or other personal
        contact, but we reserve the right to do so. We encourage you to check the date of this Policy whenever you visit
        the
        Site or use the Services so that you will know when you need to review the Policy for modifications.
      </Text>

      <Heading size='md' mt={4}>INFORMATION WE COLLECT</Heading>
      <Text mt={2}>
        We collect several types of information from and about users of our Site, including information:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          By which you may be personally identified, such as name, postal address, email address, and telephone
          number (“personal information”);
        </ListItem>
        <ListItem>
          That is about you but individually does not identify you; and/or
        </ListItem>
        <ListItem>
          About your internet connection, the equipment you use to access our Site, and usage details.
        </ListItem>
      </List>
      <Text mt={4}>
        We collect this information:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Directly from you when you provide it to us; or
        </ListItem>
        <ListItem>
          Automatically as you navigate through the Site. Information collected automatically may include usage
          details, IP addresses, and information collected through cookies and other tracking technologies.
        </ListItem>
      </List>

      <Heading size='md' mt={4}>HOW WE COLLECT INFORMATION</Heading>
      <Heading size='sm' mt={4} fontStyle='italic'>Information You Provide to Us</Heading>
      <Text mt={2}>
        The information we collect on or through our Site may include:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Information that you provide by filling in forms on our Site. This includes information provided at the time
          of registration to use the Services and during your use of the Services. We may also ask you for information
          when you report a problem with our Site.
        </ListItem>
        <ListItem>
          Information you provide to third-party applications or services in connection with your use of the Site or
          Services, in which you may be disclosing certain personal information to the applicable third party. That
          party may disclose some or all of your collected information to Clarifees LLC dba Smartdata.
        </ListItem>
        <ListItem>
          Records and copies of your correspondence (including email addresses), if you contact us.
        </ListItem>
        <ListItem>
          Your responses to surveys that we might ask you to complete for research purposes.
        </ListItem>
        <ListItem>
          Details of transactions you carry out through our Site and of the fulfillment of your orders. You may be
          required to provide financial information before placing an order through our Site.
        </ListItem>
        <ListItem>
          Your search queries on the Site.
        </ListItem>
        <ListItem>
          Information gathered by one or more advertisers or analytics service providers, including, for example,
          Google.
        </ListItem>
        <ListItem>
          You also may provide information to be published or displayed, or posted, on public areas of the Site, or
          transmitted to other users of the Site or third parties. Your User Contributions are posted on and transmitted
          to others at your own risk. Please be aware that no security measures are perfect or impenetrable.
          Additionally, we cannot control the actions of other users of the Site with whom you may choose to share your
          User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed
          by unauthorized persons.
        </ListItem>
      </List>

      <Heading size='sm' mt={4} fontStyle='italic'>Information We Collect Through Automatic Data Collection
        Technologies</Heading>
      <Text mt={2}>
        As you navigate through and interact with our Site, we may use automatic data collection technologies to collect
        certain information about your equipment, browsing actions, and patterns, including:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Details of your visits to our Site, including traffic data, location data, logs, and other communication data
          and the resources that you access and use on the Site.
        </ListItem>
        <ListItem>
          Information about your computer and internet connection, including your IP address, operating system, and
          browser type.
        </ListItem>
      </List>
      <Text mt={4}>
        The information we collect automatically may include personal information, which helps us to improve our Site
        and to deliver a better and more personalized service, including by enabling us to:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Estimate our audience size and usage patterns.
        </ListItem>
        <ListItem>
          Store information about your preferences, allowing us to customize our Site according to your individual
          interests.
        </ListItem>
        <ListItem>
          Speed up your searches.
        </ListItem>
        <ListItem>
          Recognize you when you return to our Site.
        </ListItem>
      </List>
      <Text mt={4}>The technologies we use for this automatic data collection may include:</Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may
          refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select
          this setting you may be unable to access certain parts of our Site. Unless you have adjusted your browser
          setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our
          Site.
        </ListItem>
        <ListItem>
          Web Beacons. Pages of our Site may contain small electronic files known as web beacons (also referred to as
          clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have
          visited those pages or opened an email and for other related website statistics (for example, recording the
          popularity of certain website content and verifying system and server integrity).
        </ListItem>
      </List>

      <Heading size='md' mt={4}>HOW WE USE AND DISCLOSE YOUR INFORMATION</Heading>
      <Grid templateColumns={{ base: '1fr', md: '20% 1fr' }} gap={4} mt={4}>
        <Heading size='sm' fontStyle='italic' fontWeight='normal' textDecoration='underline'>
          Business Purposes
        </Heading>
        <Box>
          <Text>
            Clarifees LLC dba Smartdata may use and disclose your information in
            order to operate the Site and provide the Services, including:
          </Text>
          <List spacing={2} mt={2} pl={6} styleType='disc'>
            <ListItem>
              to provide you with our products, content, and services, including to process your requests for Services
              and manage and administer your Clarifees LLC dba Smartdata account;
            </ListItem>
            <ListItem>
              to improve the content, functionality, and usability of the Site and Services;
            </ListItem>
            <ListItem>
              for security or fraud-prevention purposes;
            </ListItem>
            <ListItem>
              for effective customer service;
            </ListItem>
            <ListItem>
              to provide a personalized advertising experience when you visit our Site;
            </ListItem>
            <ListItem>
              to provide information and notices related to your use of our Site and Services;
            </ListItem>
            <ListItem>
              to enforce or apply our <Link href={termsOfServiceLink} isExternal color='blue.500'>Terms of
              Service</Link> and other agreements, including for billing and collection purposes;
            </ListItem>
            <ListItem>
              to protect the rights, property or safety of Clarifees LLC dba Smartdata, our customers, and others;
            </ListItem>
            <ListItem>
              to offer opportunities for participating in surveys and providing feedback to us.
            </ListItem>
          </List>
        </Box>
      </Grid>

      <Grid templateColumns={{ base: '1fr', md: '20% 1fr' }} gap={4} mt={4}>
        <Heading size='sm' fontStyle='italic' fontWeight='normal' textDecoration='underline'>Vendors</Heading>
        <Text>
          Clarifees LLC dba Smartdata may use third-party companies, agents, or
          contractors to perform services on our behalf. For example, we may
          partner with other companies to personalize our web pages, manage our
          email marketing list, analyze our website or usage information, detect
          fraud, process payments, collect debts, analyze or enhance the data
          collected by the Site (including user's interaction with the Services), or
          otherwise assist Clarifees LLC dba Smartdata in better serving its
          customers. During the course of providing Clarifees LLC dba Smartdata
          with these services, these third-parties may have access to your personal
          and non-personal information in order to provide the services we
          requested from those third-parties.
        </Text>
      </Grid>

      <Grid templateColumns={{ base: '1fr', md: '20% 1fr' }} gap={4} mt={4}>
        <Heading size='sm' fontStyle='italic' fontWeight='normal' textDecoration='underline'>Aggregated Data</Heading>
        <Text>
          Clarifees LLC dba Smartdata may combine your personal information
          and other information collected about your use of the Site and Services,
          and also supplement with information from external sources for research
          and measurement purposes, including measuring the effectiveness of
          content, advertising or programs. We may share with third parties such
          aggregated, non-personally identifiable information.
        </Text>
      </Grid>

      <Grid templateColumns={{ base: '1fr', md: '20% 1fr' }} gap={4} mt={4}>
        <Heading size='sm' fontStyle='italic' fontWeight='normal' textDecoration='underline'>Consent</Heading>
        <Text>
          Clarifees LLC dba Smartdata may use personal information about you
          for other purposes that are disclosed to you at the time we collect the
          information and/or with your consent.
        </Text>
      </Grid>

      <Grid templateColumns={{ base: '1fr', md: '20% 1fr' }} gap={4} mt={4}>
        <Heading size='sm' fontStyle='italic' fontWeight='normal' textDecoration='underline'>Merger or
          Acquisition</Heading>
        <Text>
          In the event that Clarifees LLC dba Smartdata is acquired by or merged
          with a third-party, we reserve the right to sell or otherwise transfer any
          and all information we have collected from you as part of that
          transaction and such third-party will assume the rights and obligations
          with respect to that information as described in this Policy.
        </Text>
      </Grid>

      <Grid templateColumns={{ base: '1fr', md: '20% 1fr' }} gap={4} mt={4}>
        <Heading size='sm' fontStyle='italic' fontWeight='normal' textDecoration='underline'>As Permitted by
          Law</Heading>
        <Text>
          Clarifees LLC dba Smartdata may be required to disclose personal
          information by law or legal process, to protect the security or integrity of
          our databases or this Site, to protect and defend the rights or property of
          Clarifees LLC dba Smartdata, or to take precautions against legal
          liability. We reserve the right to contact appropriate authorities when
          activities occur on our Site that are illegal or violate our policies.
        </Text>
      </Grid>

      <Heading size='md' mt={4}>INTERNATIONAL VISITORS & TRANSFER OF DATA</Heading>
      <Text mt={2}>
        Clarifees LLC dba Smartdata is located in the United States and, if data originates outside the United States,
        we
        intend to transfer data collected by us to the United States. If you are located outside of the United States,
        information that we collect will be processed and stored in the United States, a jurisdiction in which the data
        protection and privacy laws may not offer the same level of protection as those in the country where you reside
        or
        are a citizen. Subject to applicable law, by using the Site or the Services, you consent to the transfer to and
        processing of information in the United States. Where necessary under applicable law, we may employ appropriate
        cross-border transfer methods governing personal data. If you have questions regarding these safeguards, please
        contact us at the address provided in this Policy.
      </Text>

      <Heading size='md' mt={4}>YOUR PRIVACY RIGHTS</Heading>
      <Text mt={2}>
        If you do not want your personal information used by Clarifees LLC dba Smartdata in accordance with this Policy,
        you should not use the Site and Services or submit personal information to Clarifees LLC dba Smartdata.
        You can also disable cookies and other tracking and geolocation technologies on your devices and opt-out of
        marketing communications by following the opt-out or unsubscribe instructions included in those communications.
        You can request the deletion of your personal information. If you request the deletion of your personal
        information
        this may affect your ability to access and use the Services. Upon your request, Clarifees LLC dba Smartdata will
        use
        its commercially reasonable efforts to delete your personal information from our active databases and where
        feasible
        from our back-up media. It may not be possible to remove every record of the information you have provided to
        the
        Site or Services from our servers. To submit a request to delete your personal information, send us an email or
        a
        written letter to the contact information below, that contains the following information:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>Your full name;</ListItem>
        <ListItem>Your username or account number associated with your use of the Service (if any).</ListItem>
      </List>
      <Text mt={2}>
        If you notify us to exercise your rights under this Policy, we reserve the right to contact you and/or otherwise
        request additional information from you in order to confirm your identity and your right to access your personal
        information or otherwise exercise your rights. If you have an email address linked to your account related to
        your
        use of the Service, please use that email address to submit your request for verification purposes.
      </Text>
      <Text mt={2}>
        Please note that there may be restrictions on your ability to exercise your privacy rights. For example, we may
        need
        to retain some of your personal information to comply with our legal or contractual obligations or for other
        legitimate interests.
      </Text>

      <Heading size='md' mt={4}>YOUR STATE PRIVACY RIGHTS</Heading>
      <Text mt={2}>
        State consumer privacy laws may provide their residents with additional rights regarding our use of their
        personal information. Users residing in the following States maintain the following rights while using our Site:
      </Text>

      <Grid templateColumns={{ base: '1fr', md: '20% 1fr' }} gap={4} mt={4}>
        <Heading size='sm' fontStyle='italic' fontWeight='normal' textDecoration='underline'>
          California
        </Heading>
        <Text>To learn more about California residents’ privacy rights, visit <Link
          href="https://www.oag.ca.gov/privacy/ccpa" isExternal
          color="blue.500">https://www.oag.ca.gov/privacy/ccpa</Link>. California’s “Shine the Light” law (Civil Code
          Section § 1798.83) permits users of our App that are California residents to request certain information
          regarding our disclosure of personal information to third parties for their direct marketing
          purposes.
        </Text>
      </Grid>

      <Grid templateColumns={{ base: '1fr', md: '20% 1fr' }} gap={4} mt={4}>
        <Heading size='sm' fontStyle='italic' fontWeight='normal' textDecoration='underline'>
          Colorado, Connecticut, Virginia & Utah
        </Heading>
        <Text>
          Users residing in Colorado, Connecticut, Virginia and Utah are provided with the rights to: (i) confirm
          whether We process their personal information, (ii) access and delete certain personal information, (iii)
          data portability, and (iv) opt-out of personal data processing for targeted advertising and sales.
          Furthermore, Users residing in Colorado, Connecticut, and Virginia are provided with the additional rights
          to: (i) correct inaccuracies in their personal information, taking into account the information’s nature
          processing purpose, and (ii) opt-out of profiling in furtherance of decisions that produce legal or
          similarly significant effects.
        </Text>
      </Grid>

      <Grid templateColumns={{ base: '1fr', md: '20% 1fr' }} gap={4} mt={4}>
        <Heading size='sm' fontStyle='italic' fontWeight='normal' textDecoration='underline'>
          Nevada
        </Heading>
        <Text>
          Users residing in Nevada are provided with a limited right to opt-out of certain personal information sales.
          However, please know that Clarifees LLC dba Smartdata does not currently sell any data of its Users that would
          trigger Nevada’s opt-out statute.
        </Text>
      </Grid>

      <Grid templateColumns={{ base: '1fr', md: '20% 1fr' }} gap={4} mt={4}>
        <Heading size='sm' fontStyle='italic' fontWeight='normal' textDecoration='underline'>
          Nevada
        </Heading>
        <Text>
          Users residing in Nevada are provided with a limited right to opt-out of certain personal information sales.
          However, please know that Clarifees LLC dba Smartdata does not currently sell any data of its Users that would
          trigger Nevada’s opt-out statute.
        </Text>
      </Grid>

      <Text mt={2}>
        To exercise any of the above-mentioned consumer rights or to appeal a decision regarding a consumer rights
        request, please email <Link href="mailto:hello@smartdatawealth.com" isExternal
                                    color="blue.500">hello@smartdatawealth.com</Link>.
      </Text>

      <Heading size='md' mt={4}>CHILDREN’S PRIVACY</Heading>
      <Text mt={2}>
        We do not knowingly collect, maintain, or use information from children under 18 years of age, and no part of our
        Services are directed towards children. If you are under 18, do not use or provide any personal information on the
        Site or through any of the Services.
      </Text>

      <Text mt={2}>
        If you believe Clarifees LLC dba Smartdata may have any information from or about a child under 18, please
        contact us using the contact information below.
      </Text>

      <Heading size='md' mt={4}>THIRD-PARTY LINKS</Heading>
      <Text mt={2}>
        The Site may contain links to other third-party websites, products, or services which Clarifees LLC dba Smartdata
        does not own or operate. We are not responsible for the content provided by, or the privacy practices of, these third
        parties. Clarifees LLC dba Smartdata makes no representation about the accuracy of any information or data
        appearing on any third-party website. We encourage you to read the third-party’s privacy policies before providing
        any information to them.
      </Text>

      <Heading size='md' mt={4}>SECURITY</Heading>
      <Text mt={2}>
        We have implemented measures designed to secure your personal information from accidental loss and from
        unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers
        behind firewalls. Any payment transactions will be encrypted using SSL technology.
      </Text>

      <Text mt={2}>
        The safety and security of your information also depends on you. Where we have given you (or where you have
        chosen) a password for access to certain parts of our Site, you are responsible for keeping this password confidential.
        We ask you not to share your password with anyone. We urge you to be careful about giving out information in public
        areas of the Site like message boards. The information you share in public areas may be viewed by any user of the
        Site.
      </Text>

      <Text mt={2}>
        Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to
        protect your personal information, we cannot guarantee the security of your personal information transmitted to our
        Site. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any
        privacy settings or security measures contained on the Site.
      </Text>

      <Heading size='md' mt={4}>DATA RETENTION</Heading>
      <Text mt={2}>
        Clarifees LLC dba Smartdata may retain the personal and non-personal information or other collected data,
        individually or in the aggregate, as described above, after you delete your account or have ceased using the Service.
        However, we do not guarantee that any information will continue to be available after such deletion or cessation.
      </Text>

      <Heading size='md' mt={4}>VIEWING AND UPDATING YOUR INFORMATION</Heading>
      <Text mt={2}>
        You are responsible for updating and maintaining the accuracy of your personal information that you provide to or
        through the Site and Services. If you believe you have submitted inaccurate information through the Site or
        Services, and would like Clarifees LLC dba Smartdata to change such information, please contact us at the address
        below.
      </Text>

      <Heading size='md' mt={4}>CONTACT INFORMATION</Heading>
      <Text mt={2}>
        You may use the contact information below to request access to and amendment or deletion of the personal
        information that Clarifees LLC dba Smartdata has about you. You may also submit a general privacy-related inquiry
        or complaint in accordance with applicable laws and regulations. Clarifees LLC dba Smartdata will respond to such
        requests in accordance with applicable laws.
      </Text>

      <Text mt={2}>
        Please issue such requests by contacting Clarifees LLC dba Smartdata:
      </Text>

      <Text mt={2} fontWeight="bold">Clarifees LLC dba Smartdata</Text>
      <Text fontWeight="bold">Attn: Privacy</Text>
      <Link href="mailto:hello@smartdatawealth.com" fontWeight="bold" color="blue.500">
        hello@smartdatawealth.com
      </Link>

    </Box>
  );
}

export default PrivacyPolicy;
