import React from 'react';
// Chakra imports
import { Box, Heading, Link, List, ListItem, Text, useColorModeValue } from '@chakra-ui/react';

const TermsOfService: React.FC = () => {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.500";
  const privacyPolicyLink = "https://www.smartdatawealth.com/privacy-policy";

  return (
    <Box p={{ base: 5, md: 8 }} color={textColor}>
      <Heading textAlign='center' mt={4}>TERMS OF SERVICE</Heading>
      <Text mt={4} color={textColorSecondary}>
        Last updated: 6/3/24
      </Text>
      <Heading size='md' mt={4}>ACCEPTANCE OF TERMS</Heading>
      <Text mt={2}>
        These terms of service (these “<b>Terms</b>”) are entered into by and between you and Clarifees LLC dba
        Smartdata, LLC
        (“<b>Clarifees LLC dba Smartdata</b>”, “<b>Company</b>”, “<b>we</b>” or “<b>us</b>”), together with any
        documents they expressly
        incorporate by reference, govern your access to and use of <Link href='https://www.smartdatawealth.com'
                                                                         target='_blank'
                                                                         color='blue.500'>www.smartdatawealth.com</Link> (the
        “<b>Site</b>”), including any
        content, functionality, and services offered on or through the Site, whether as a guest or a registered user.
      </Text>
      <Text mt={4}>
        Please read these Terms carefully before you start to use the Site. <b>BY ACCESSING OR USING THE SITE IN
        ANY WAY, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND
        AGREE TO BE BOUND BY THESE TERMS AND OUR PRIVACY POLICY, WHICH IS AVAILABLE ON
        THE SITE.</b> If you do not want to agree to these Terms, you must not access or use the Site.
      </Text>

      <Heading size='md' mt={4}>ELIGIBILITY</Heading>
      <Text mt={2}>
        The Site and services it describes are available only to individuals who are at least 18 years old. By using
        this Site,
        you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these
        Terms. If
        you do not meet these requirements, you must not access or use the Site.
      </Text>

      <Heading size='md' mt={4}>ACCESSING THE SITE AND ACCOUNT SECURITY</Heading>
      <Text mt={2}>
        We reserve the right to withdraw or amend this Site, and any service or material we provide on the Site, in our
        sole
        discretion without notice. We will not be liable if for any reason all or any part of the Site is unavailable at
        any time
        or for any period. From time to time, we may restrict user access to some parts of the Site or the entire Site.
      </Text>

      <Text mt={4}>
        You are responsible for:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Making all arrangements necessary for you to have access to the Site; and
        </ListItem>
        <ListItem>
          Ensuring that all persons who access the Site through your internet connection are aware of these Terms and
          comply with them.
        </ListItem>
      </List>

      <Text mt={2}>
        To access the Site or some of the resources it offers, you may be asked to provide certain
        registration details or other information. It is a condition of your use of the Site that all the information
        you provide on the Site is correct, current, and complete. You agree that all information you provide to
        register with this Site or otherwise, including, but not limited to, through the use of any interactive
        features on the Site, is governed by our Privacy Policy, and you consent to all actions we take with
        respect to your information consistent with our <Link href={privacyPolicyLink} color="blue.500" target='_blank'>
        Privacy Policy
      </Link>.
      </Text>
      <Text mt={2}>
        If you choose, or are provided with, a username, password, or any other piece of information as part
        of our security procedures, you must treat such information as confidential, and you must not disclose
        it to any other person or entity. You also acknowledge that your account is personal to you and agree
        not to provide any other person with access to this Site or portions of it using your username,
        password, or other security information. You agree to notify us immediately of any unauthorized access
        to or use of your username or password or any other breach of security. You also agree to ensure that
        you exit your account at the end of each session. You should use particular caution when accessing
        your account from a public or shared computer so that others are not able to view or record your
        password or other personal information.
      </Text>

      <Text mt={2}>
        We have the right to disable any username, password, or other identifier, whether chosen by you or provided by
        us,
        at any time, in our sole discretion for any or no reason, including if, in our opinion, you have violated any
        provision
        of these Terms.
      </Text>

      <Heading size='md' mt={4}>DATA COLLECTION AND USE</Heading>
      <Text mt={2}>
        YOU AGREE THAT USE OF THIS SITE, SERVICES, AND CONTENT IS AT YOUR SOLE RISK. Clarifees
        LLC dba Smartdata MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
        COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY SITES LINKED TO THIS SITE
        AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
        WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (III) ANY
        UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
        INFORMATION STORED THEREIN. IN ADDITION THERETO, THE SITE, SERVICES AND CONTENT ARE
        NOT A SUBSTITUTE FOR PROFESSIONAL FINANCIAL ADVICE.
      </Text>
      <Text mt={2}>
        For purposes of clarification, Clarifees LLC dba Smartdata is not a financial planner, broker or tax advisor;
        nor is
        Clarifees LLC dba Smartdata intended to provide legal advice, tax advice, accounting advice or financial advice
        (other than refer users to third-party advisors registered or chartered as fiduciaries “<b>Advisor</b>” with a
        regulatory body
        in the United States).
      </Text>
      <Text mt={2}>
        Additionally, Advisors may register for an account and use our Site and services as well. Once an Advisor
        creates an
        account with us, they will have the ability to add their clientele “<b>Prospects</b>” to their Clarifees LLC dba
        Smartdata
        account. When Prospects are added to the Advisor’s account, we will send the Prospect an invitation to use the Site and
        services. If the Prospect so wishes, they may register for and access their own account with us.
      </Text>
      <Text mt={2}>
        Prospects become a Clarifees LLC dba Smartdata user when they access or use the Site and services. By using our Site
        and services, you authorize us to collect, use, and store financial data from your connected brokerage accounts.
        This
        data includes, but is not limited to, AUM, holdings, transactions, fees, investment statements, and tax returns
        (the
        “<b>Financial Data</b>”). We maintain control over all Financial Data shared with us and may use this Financial
        Data to
        improve our services, develop new features, and for other business purposes.
      </Text>

      <Heading size='md' mt={4}>PROHIBITED USES</Heading>
      <Text mt={2}>
        You may use the Site only for lawful purposes and in accordance with these Terms. You agree not to use the Site:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          In any way that violates any applicable federal, state, local, or international law or regulation (including,
          without limitation, any laws regarding the export of data or software to and from the US or other countries).
        </ListItem>
        <ListItem>
          For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to
          inappropriate content, asking for personally identifiable information, or otherwise.
        </ListItem>
        <ListItem>
          To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the
          Content Standards set out in these Terms.
        </ListItem>
        <ListItem>
          To transmit, or procure the sending of, any advertising or promotional material without our prior written
          consent, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation.
        </ListItem>
        <ListItem>
          To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or
          entity.
        </ListItem>
        <ListItem>
          To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Site, or which, as
          determined by us, may harm the Company or users of the Site or expose them to liability.
        </ListItem>
      </List>
      <Text mt={4}>
        Additionally, you agree not to:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Use the Site in any manner that could disable, overburden, damage, or impair the Site or interfere with any
          other party’s use of the Site, including their ability to engage in real-time activities through the Site.
        </ListItem>
        <ListItem>
          Use any robot, spider, or other automatic device, process, or means to access the Site for any purpose,
          including monitoring or copying any of the material on the Site.
        </ListItem>
        <ListItem>
          Use any manual process to monitor or copy any of the material on the Site, or for any other unauthorized
          purpose without our prior written consent.
        </ListItem>
        <ListItem>
          Use any device, software, or routine that interferes with the proper working of the Site.
        </ListItem>
        <ListItem>
          Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or
          technologically harmful.
        </ListItem>
        <ListItem>
          Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Site, the server
          on which the Site is stored, or any server, computer, or database connected to the Site.
        </ListItem>
        <ListItem>
          Attack the Site via a denial-of-service attack or a distributed denial-of-service attack.
        </ListItem>
        <ListItem>
          Otherwise attempt to interfere with the proper working of the Site.
        </ListItem>
      </List>

      <Heading size='md' mt={4}>INTELLECTUAL PROPERTY RIGHTS</Heading>
      <Text mt={2}>
        This Site and its entire contents, features, and functionality (including but not limited to all information,
        software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are
        owned by the Company, its licensors, or other providers of such material and are protected by United States
        and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary
        rights laws.
      </Text>
      <Text mt={2}>
        These Terms permit you to use the Site for your personal, non-commercial use only. You must not reproduce,
        distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store,
        or
        transmit any of the material on our Site, except as follows:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing
          those materials.
        </ListItem>
        <ListItem>
          You may store files that are automatically cached by your Web browser for display enhancement purposes.
        </ListItem>
        <ListItem>
          You may print or download one copy of a reasonable number of pages of the Site for your own personal,
          non-commercial use and not for further reproduction, publication, or distribution.
        </ListItem>
        <ListItem>
          If we provide desktop, mobile, or other applications for download, you may download a single copy to your
          computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by
          our end user license agreement for such applications.
        </ListItem>
        <ListItem>
          If we provide social media features, with certain content, you may take such actions as are enabled by such
          features.
        </ListItem>
      </List>
      <Text mt={2}>
        You must not:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Modify copies of any materials from this Site.
        </ListItem>
        <ListItem>
          Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying
          text.
        </ListItem>
        <ListItem>
          Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from
          this Site.
        </ListItem>
        <ListItem>
          Access or use for any commercial purposes any part of the Site or any services or materials available through
          the Site.
        </ListItem>
      </List>
      <Text mt={2}>
        If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of
        the Site
        in breach of the Terms, your right to use the Site will stop immediately and you must, at our option, return or
        destroy
        any copies of the materials you have made. No right, title, or interest in or to the Site or any content on the
        Site is
        transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Site not
        expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other
        laws.
      </Text>

      <Heading size='md' mt={4}>USER CONTRIBUTIONS</Heading>
      <Text mt={2}>
        The Site may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and
        other
        interactive features (collectively, “<b>Interactive Services</b>”) that allow users to post, submit, publish,
        display, or
        transmit to other users or other persons (hereinafter, “<b>post</b>”) content or materials (collectively, “<b>User
        Contributions</b>”) on or through the Site.
      </Text>
      <Text mt={2}>
        All User Contributions must comply with the Content Standards set out in these Terms.
      </Text>
      <Text mt={2}>
        Any User Contribution you post to the Site will be considered non-confidential and non-proprietary. By providing
        any User Contribution on the Site, you grant us and our affiliates and service providers, and each of their and
        our
        respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute,
        and
        otherwise disclose to third parties any such material for any purpose.
      </Text>
      <Heading size='md' mt={4}>LICENSE GRANTED BY USER</Heading>
      <Text mt={2}>
        <b>You represent and warrant that:</b>
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          You own or control all rights in and to the User Contributions and have the right to grant the license granted
          above to us and our affiliates and service providers, and each of their and our respective licensees,
          successors, and assigns.
        </ListItem>
        <ListItem>
          All of your User Contributions do and will comply with these Terms.
        </ListItem>
      </List>
      <Heading size='md' mt={4}>USER RESPONSIBILITY</Heading>
      <Text mt={2}>
        You understand and acknowledge that you are responsible for any User Contributions you submit or
        contribute, and you, not the Company, have full responsibility for such content, including its legality,
        reliability, accuracy, and appropriateness.
      </Text>
      <Text mt={2}>
        We are not responsible or liable to any third party for the content or accuracy of any User Contributions posted
        by
        you or any other user of the Site.
      </Text>

      <Heading size='md' mt={4}>CONTENT STANDARDS</Heading>
      <Text mt={2}>
        These Content Standards apply to any and all User Contributions and use of Interactive Services. User
        Contributions
        must in their entirety comply with all applicable federal, state, local, and international laws and regulations.
        Without
        limiting the foregoing, User Contributions must not:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful,
          inflammatory, or otherwise objectionable.
        </ListItem>
        <ListItem>
          Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion,
          nationality, disability, sexual orientation, or age.
        </ListItem>
        <ListItem>
          Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any
          other person.
        </ListItem>
        <ListItem>
          Violate the legal rights (including the rights of publicity and privacy) of others or contain any material
          that
          could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may
          be in conflict with these Terms and our <Link href={privacyPolicyLink} color='blue.500'>Privacy Policy</Link>.
        </ListItem>
        <ListItem>
          Be likely to deceive any person.
        </ListItem>
        <ListItem>
          Promote any illegal activity, or advocate, promote, or assist any unlawful act.
        </ListItem>
        <ListItem>
          Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any
          other person.
        </ListItem>
        <ListItem>
          Impersonate any person, or misrepresent your identity or affiliation with any person or organization.
        </ListItem>
        <ListItem>
          Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or
          advertising.
        </ListItem>
        <ListItem>
          Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not
          the case.
        </ListItem>
      </List>

      <Heading size='md' mt={4}>CONTENT</Heading>
      <Text mt={2}>
        The text, images, videos, audio clips, software and other content generated, provided, or otherwise made
        accessible
        on or through the Site (collectively, “<b>Content</b>”) are contributed by us and our licensors. The Content and
        the Site are
        protected by U.S. and international copyright laws. We and our licensors retain all proprietary rights in the
        Site and
        the Content made available on or through the Site, and, except as expressly set forth in these Terms, no rights
        are
        granted to any Content. Subject to these Terms, we grant each user of the Site a worldwide, non-exclusive, non-
        sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for
        viewing,
        browsing and using the functionality of the Site. All Content is for general informational purposes only. We
        reserve
        the right, but do not have any obligation to monitor, remove, edit, modify or remove any Content, in our sole
        discretion, at any time for any reason or for no reason at all.
      </Text>

      <Heading size='md' mt={4}>TRADEMARKS</Heading>
      <Text mt={2}>
        The Company name, the terms, and all related names, logos, product and service names, designs, and slogans are
        trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written
        permission of the Company. All other names, logos, product and service names, designs, and slogans on this Site
        are
        the trademarks of their respective owners.
      </Text>

      <Heading size='md' mt={4}>CLAIMS OF COPYRIGHT INFRINGEMENT</Heading>
      <Text mt={2}>
        Claims of copyright infringement should be sent to our designated agent. Please send notices to:
        <Link href='mailto:hello@smartdatawealth.com' color="blue.500"> hello@smartdatawealth.com</Link>. It is the
        Company’s policy to terminate
        the user accounts of repeat infringers.
      </Text>

      <Heading size='md' mt={4}>MONITORING AND ENFORCEMENT; TERMINATION</Heading>
      <Text mt={2}>
        We have a right to:
      </Text>
      <List spacing={2} mt={2} pl={6} styleType='disc'>
        <ListItem>
          Remove or refuse to post any User Contributions for any or no reason in our sole discretion.
        </ListItem>
        <ListItem>
          Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole
          discretion, including if we believe that such User Contribution violates these Terms, including the Content
          Standards, infringes any intellectual property right or other right of any person or entity, threatens the
          personal safety of users of the Site or the public, or could create liability for the Company.
        </ListItem>
        <ListItem>
          Disclose your identity or other information about you to any third party who claims that material posted by
          you violates their rights, including their intellectual property rights or their right to privacy.
        </ListItem>
        <ListItem>
          Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or
          unauthorized use of the Site.
        </ListItem>
        <ListItem>
          Terminate or suspend your access to all or part of the Site for any or no reason, including without
          limitation, any violation of these Terms.
        </ListItem>
        <ListItem>
          Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or
          court order requesting or directing us to disclose the identity or other information of anyone posting any
          materials on or through the Site. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND
          SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY/ANY OF THE FOREGOING PARTIES
          DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </ListItem>
      </List>
      <Text mt={2}>
        However, we cannot review all material before it is posted on the Site and cannot ensure prompt removal of
        objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction
        regarding transmissions, communications, or content provided by any user or third party. We have no liability or
        responsibility to anyone for performance or nonperformance of the activities described in this section.
      </Text>

      <Heading size='md' mt={4}>RELIANCE ON INFORMATION POSTED</Heading>
      <Text mt={2}>
        The information presented on or through the Site is made available solely for general information purposes. We
        do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such
        information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance
        placed on such materials by you or any other visitor to the Site, or by anyone who may be informed of any of its
        contents.
      </Text>
      <Text mt={2}>
        This Site may include content provided by third parties, including materials provided by other users, bloggers,
        and third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions
        expressed in these materials, and all articles and responses to questions and other content, other than the
        content provided by the Company, are solely the opinions and the responsibility of the person or entity
        providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not
        responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any
        third parties.
      </Text>

      <Heading size='md' mt={4}>THIRD-PARTY LINKS</Heading>
      <Text mt={2}>
        If the Site contains links to other sites and resources provided by third parties, these links are provided for
        your convenience only. This includes links contained in advertisements, including banner advertisements and
        sponsored links. We have no control over the contents of those sites or resources and accept no responsibility
        for them or for any loss or damage that may arise from your use of them. If you decide to access any of the
        third-party websites linked to this Site, you do so entirely at your own risk and subject to the terms and
        conditions of use for such websites.
      </Text>

      <Heading size='md' mt={4}>INDEMNIFICATION</Heading>
      <Text mt={2}>
        You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers,
        and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers,
        successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs,
        expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these
        Terms or your use of the Site, including, but not limited to, your User Contributions, any use of the Site’s
        content, services, and products other than as expressly authorized in these Terms, or your use of any
        information obtained from the Site.
      </Text>

      <Heading size='md' mt={4}>DISCLAIMER OF WARRANTIES</Heading>
      <Text mt={2}>
        You understand that we cannot and do not guarantee or warrant that files available for downloading from the
        internet
        or the Site will be free of viruses or other destructive code. You are responsible for implementing sufficient
        procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of
        data
        input and output, and for maintaining a means external to our Site for any reconstruction of any lost data. TO
        THE
        FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
        CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
        TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
        COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
        SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR YOUR DOWNLOADING OF
        ANY MATERIAL POSTED ON IT, OR ON ANY SITE LINKED TO IT.
      </Text>

      <Text mt={2}>
        YOUR USE OF THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
        SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
        THROUGH THE SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY
        PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH
        RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SITE. WITHOUT
        LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
        ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SITE, ITS
        CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE,
        RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
        SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS, OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE
        WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
      </Text>

      <Text mt={2}>
        TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL
        WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
        INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-
        INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
      </Text>

      <Text mt={2}>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED
        UNDER APPLICABLE LAW.
      </Text>

      <Heading size='md' mt={4}>LIMITATION OF LIABILITY</Heading>
      <Text mt={2}>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL Clarifees LLC dba Smartdata,
        ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS, OR AFFILIATES BE LIABLE FOR
        ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING
        WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES,
        RESULTING FROM (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SITE; (II)
        ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SITE, INCLUDING WITHOUT
        LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD
        PARTIES; (III) ANY CONTENT OBTAINED FROM THE SITE; AND (IV) UNAUTHORIZED ACCESS, USE
        OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT, WHETHER BASED ON WARRANTY,
        CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT
        WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET
        FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
      </Text>

      <Text mt={2}>
        CLARIFEES LLC DBA SMARTDATA PROVIDES A PLATFORM FOR CONNECTING INDIVIDUALS
        SEEKING FINANCIAL ADVICE WITH INDEPENDENT ADVISORS. CLARIFEES LLC DBA SMARTDATA
        DOES NOT PROVIDE FINANCIAL ADVICE, ENDORSE ANY PARTICULAR ADVISOR, NOR EVALUATE
        THE QUALITY OF ANY ADVICE RECEIVED BY YOU FROM SUCH ADVISORS. YOU ACKNOWLEDGE
        THAT CLARIFEES LLC DBA SMARTDATA IS NOT LIABLE FOR ANY ADVICE, INFORMATION, OR
        SERVICES PROVIDED BY ANY ADVISORS NOR FOR ANY DECISIONS MADE BY YOU AND BASED ON
        ADVICE, INFORMATION, OR SERVICES RECEIVED THROUGH THE USE OF THIS SITE OR OUR
        SERVICES.
      </Text>

      <Text mt={2}>
        FURTHER, YOU ACKNOWLEDGE THAT CLARIFEES LLC DBA SMARTDATA IS NOT LIABLE FOR ANY
        LOSS OR DAMAGE ARISING FROM YOUR RELIANCE ON FINANCIAL DATA STORED, ANALYZED, OR
        PROCESSED THROUGH THE SITE, INCLUDING BUT NOT LIMITED TO, ASSET MANAGEMENT,
        HOLDINGS, TRANSACTIONS, FEES, INVESTMENT STATEMENTS, AND TAX RETURNS INFORMATION.
        CLARIFEES LLC DBA SMARTDATA ROLE IS LIMITED TO FACILITATING THE AVAILABILITY OF THE
        SITE AND SERVICES AND DOES NOT ASSUME LIABILITY FOR ANY FINANCIAL DATA PROVIDED TO
        THE SITE OR GENERATED THROUGH ITS USE.
      </Text>

      <Text mt={2}>
        YOUR USE OF THE SITE AND RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR
        OWN RISK.
      </Text>

      <Heading size='md' mt={4}>ARBITRATION</Heading>
      <Text mt={2}>
        We may require you to submit any disputes arising from these Terms or use of the Site, including disputes
        arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to
        final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying
        Delaware law.
      </Text>

      <Heading size='md' mt={4}>GEOGRAPHIC RESTRICTIONS</Heading>
      <Text mt={2}>
        The owner of the Site is based in the United States. We provide this Site for use only by persons located in the
        United States. We make no claims that the Site or any of its content is accessible or appropriate outside of the
        United States, you do so on your own initiative and are responsible for compliance with local laws.
      </Text>

      <Heading size='md' mt={4}>MISCELLANEOUS</Heading>
      <Text mt={2}>
        All matters relating to the Site and these Terms, and any dispute or claim arising therefrom or related thereto
        (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance
        with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision
        or rule.
        These Terms constitute the entire agreement between us regarding the Site and supersedes and merges any prior
        proposals, understandings and contemporaneous communications. If any provision of these Terms is held to be
        invalid or unenforceable, that provision shall be limited or eliminated to the minimum extent necessary so that
        these Terms shall otherwise remain in full force and effect and enforceable. In order for any waiver of
        compliance with these Terms to be binding, we must provide you with written notice of such waiver. The failure
        of either party to enforce its rights under these Terms at any time for any period will not be construed as a
        waiver of such rights.
      </Text>

      <Heading size='md' mt={4}>CONTACT INFORMATION</Heading>
      <Text mt={2}>
        If you have any questions, complaints, or claims with respect to the Site, you may contact us at <Link
        href='mailto:hello@smartdatawealth.com' color='blue.500'>hello@smartdatawealth.com</Link>
      </Text>

      <Heading textAlign="center" mt={6}>TERMS & CONDITIONS FOR TAX GUARD</Heading>
      <Text mt={4}>
        Welcome to the Tax Guard website (the "Website"). Please read these terms of use (this "Agreement") carefully.
        By accessing the Website and clicking “agree” you, the user, (collectively with your agents, representatives,
        principals, contractors, and any person or entity acting or apparently acting on your behalf or who on behalf
        you are acting, "Company") accept the offer made by this Agreement, agree this shall be a legal contract and
        that you intend to be legally bound to a contract between the Company and Tax Guard for the use of both the
        Website and the Monitoring Services, as defined below. If you do not agree with this Agreement and do not intend
        to enter into this Agreement, please do not access this Website, use the Monitoring Services or click “agree.”
      </Text>
      <Heading size="md" mt={4}>1. Definitions.</Heading>
      <Text mt={2}>The following terms have the following meaning:</Text>
      <List spacing={2} mt={2} pl={6} styleType="disc">
        <ListItem>
          <b>1.1 "Company Client"</b> means any person or entity that is a client or customer of Company.
          "Prospective Company Client" means any person or entity that is under consideration by the Company to become a
          Company Client. Company Client(s) and Prospective Company Client(s) shall be referred hereinafter collectively
          as "Company’s Customers."
        </ListItem>
        <ListItem>
          <b>1.2 "Federal Tax Information"</b> means information with respect to a taxpayer of its federal tax
          status, tax compliance, and/or tax liabilities.
        </ListItem>
        <ListItem>
          <b>1.3 "Monitoring Services"</b> means the services performed by Tax Guard for the Company of
          obtaining and/or monitoring Federal Tax Information by obtaining such information from the Internal Revenue
          Service about Company Customers and delivering the information to Company. Monitoring Services do not include
          Tax Guard Resolution Services (as defined below).
        </ListItem>
        <ListItem>
          <b>1.4 "Monthly Fee" or "Fee"</b> means the fees set forth on a fee schedule that is provided by Tax
          Guard to Company (the "Fee Schedule"). Tax Guard reserves the right to change the fees from time to time, with
          such changes referenced in the new “Fee Schedule” made available to Company.
        </ListItem>
        <ListItem>
          <b>1.5 "Form 8821"</b> means Treasury Department Internal Revenue Service ("IRS") Form 8821 Tax
          Information Authorization form (this is not a Power of Attorney).
        </ListItem>
        <ListItem>
          <b>1.6 "Tax Guard Resolution Services"</b> means tax resolution services performed by Tax Guard to
          resolve tax liabilities.
        </ListItem>
        <ListItem>
          <b>1.7 “Work Agreement"</b> means a Tax Guard standard form agreement entered into between Tax Guard
          and Tax Guard Resolution Services Clients under which Tax Guard performs the Tax Guard Resolution Services.
        </ListItem>
        <ListItem>
          <b>1.8 "Tax Guard Resolution Services Client"</b> means any person or entity for whom Tax Guard
          performs or has performed Tax Guard Resolution Services.
        </ListItem>
        <ListItem>
          <b>1.9 "Tax Guard Services"</b> means collectively the Monitoring Services and the Tax Guard
          Resolution Services.
        </ListItem>
        <ListItem>
          <b>1.10 “Party or Parties”</b> means individually and singularly either Company or Tax Guard and
          collectively and plural Company and Tax Guard.
        </ListItem>
      </List>

      <Heading size="md" mt={4}>2. Obligations of the Parties</Heading>
      <List spacing={2} mt={2} pl={6} styleType="disc">
        <ListItem>
          <b>2.1</b> Promise to read this Agreement carefully, to consult the Website regularly for up-to-date
          information about the Tax Guard Services and any changes or amendments to this Agreement, and retain it for
          future reference. Understand and agree that the terms and conditions of this Agreement govern all aspects of
          its relationship with Tax Guard, including all transactions between Tax Guard and Company and all products and
          services now or in the future offered through Tax Guard, beginning on the date when Company begins the use of
          the Monitoring Services. Tax Guard has the right to amend this Agreement at any time and without notice to
          you. Such amendments shall be effective immediately upon posting. You agree to review the Tax Guard Website
          periodically to be aware of any such amendments. Your continued use of the Tax Guard Website after such
          amendments have been made shall constitute acceptance of the amendments. Tax Guard reserves the right to
          terminate any account at any time and for any reason in its sole discretion.
          If Company determines, at any time, that it is unwilling to be bound by all of the terms and conditions of
          this Agreement, it will not use any of the Tax Guard Services.
        </ListItem>
        <ListItem>
          <b>2.2</b> CAREFULLY READ, UNDERSTAND AND ACCEPT THE TERMS AND CONDITIONS OF THIS AGREEMENT BEFORE
          IT CLICKS "AGREE" OR OTHER SIMILARLY WORDED BUTTON. COMPANY UNDERSTANDS THAT CLICKING "AGREE" IS THE LEGAL
          EQUIVALENT OF MANUALLY SIGNING THIS AGREEMENT AND COMPANY WILL BE LEGALLY BOUND BY ALL OF ITS TERMS AND
          CONDITIONS. COMPANY UNDERSTANDS THAT THIS AGREEMENT MAY BE AMENDED FROM TIME TO TIME BY TAX GUARD, WITH
          REVISED TERMS POSTED ON THE WEBSITE. COMPANY AGREES TO CHECK FOR UPDATES TO THIS AGREEMENT. COMPANY
          UNDERSTANDS THAT BY CONTINUING TO USE THE MONITORING SERVICES AND/OR ACCESSING THE WEBSITE WITHOUT OBJECTING
          TO ANY REVISED TERMS OF THIS AGREEMENT, COMPANY IS ACCEPTING THE TERMS OF THE REVISED AGREEMENT AND IT WILL BE
          LEGALLY BOUND BY ALL ITS TERMS AND CONDITIONS.
        </ListItem>
        <ListItem>
          <b>2.3</b> Obtain, for each of Company’s Customers that agrees, written consent to complete and
          execute Form 8821 by such Company’s Customers designating Tax Guard as its Form 8821 appointee to receive
          Federal Tax Information from the IRS (the "8821 Appointment"). Under an 8821 Appointment, Tax Guard is
          authorized only to receive Federal Tax Information for each Company Customer that Tax Guard has an 8821
          Appointment and has no other duty or obligation whatsoever to those Company Customers.
        </ListItem>
        <ListItem>
          <b>2.4</b> Have Company Customers execute (a) Form 8821 in strict accordance with instructions for
          Form 8821, IRS rules, U.S. Treasury regulations, letters, and memoranda, and (b) any other forms or
          authorizations provided by Tax Guard.
          The Tax Guard portal is a proprietary solution that completes Form 8821 in accordance with the above.
        </ListItem>
        <ListItem>
          <b>2.5</b> Clearly and conspicuously inform each of the Company’s Customers who execute and deliver
          to Company Form 8821 under Section 2.3, above, that the Federal Tax Information Tax Guard receives from the
          IRS about such Company Customer pursuant to the Monitoring Services shall be in turn provided and delivered to
          Company by Tax Guard and to that extent Company Customer is expressly waiving its right to privacy and
          confidentiality with respect to such Federal Tax Information and that such Federal Tax Information will be
          used, among other things, in deciding whether Company will or will not extend commercial credit to the Company
          Customer. Company covenants and agrees that in the event any Company Customer refuses to waive such rights, is
          not informed that Tax Guard will share the Federal Tax Information with Company, or withdraws any consent the
          Company immediately will inform Tax Guard in writing. Company understands it responsible for all acts and
          omissions relating to the obligations of having the Form 8821 completed and executed and for all the covenants
          including the express waiver of right to privacy and confidentiality by the Company Customer set forth in this
          Section 2.5.
        </ListItem>
        <ListItem>
          <b>2.6</b> At all times only make statements to the Company Customers and all other persons,
          entities and third parties regarding Tax Guard, the Monitoring Services and the Tax Guard Resolution Services
          that are true and accurate and not misleading or deceptive.
        </ListItem>
        <ListItem>
          <b>2.7</b> Hold harmless and indemnify Tax Guard and its directors, officers, partners,
          shareholders, members, managers, employees, agents, representatives, successors and assigns (hereinafter
          individually referred to as a "Tax Guard Indemnified Party"), from and against all losses, liabilities,
          damages, demands, claims, suits, actions, causes of action, judgments, assessments, costs and expenses,
          including, without limitation, interest, penalties, reasonable attorneys' fees, any and all expenses incurred
          in investigating, preparing or defending against any litigation or any type proceeding, commenced or
          threatened, or any claim whatsoever, and any and all amounts paid in settlement of any claim or litigation
          (collectively, "Damages"), asserted against, resulting to, imposed upon, or incurred or suffered by any Tax
          Guard Indemnified Party, directly or indirectly, as a result of, arising from, or relating directly or
          indirectly to (a) any breach or non-fulfillment of any of the covenants or agreements made by Company in this
          Agreement, (b) the failure of Company to obtain the consent of a party whose consent is required under this
          Agreement, including, but not limited to the consent permitting Tax Guard to share the Federal Tax Information
          with Company and any other form provided by Tax Guard and the consent set forth in Section 2.13, (c) Company’s
          use of the Monitoring Services, (d) any violation whatsoever by Company of any law, rule, regulation or rights
          of others in connection with or related in any way with the use of the Website, the operation or conduct of
          its business, its relationships with Company Customers or the Federal Tax Information (e) Company’s use or
          disclosure of another person’s personal, financial or credit information (f) the violation of any property or
          privacy right; or (g) the failure of Company to provide notice to any third party including the notice in
          Section 2.13. This obligation shall survive the termination or expiration of this Agreement and/or Company’s
          use of the Tax Guard Services or Website.
        </ListItem>
        <ListItem>
          <b>2.8</b> Timely pay the Monthly Fee in the then-current amount. Company will be invoiced for the
          Monthly Fee applicable to the Company in accordance with the Fee Schedule. Payment is due upon receipt of
          invoice. Undisputed portions of invoices not paid in full by Company within 30 days are subject to a late fee
          of 1.75% per month (or the maximum fee allowable by law, if less), plus all costs of collection, including
          reasonable attorneys’ fees. Tax Guard reserves the right to suspend access to the Website and the performance
          of the Monitoring Services to any delinquent account without notice. Company acknowledges and agrees that the
          Monthly Fee is, and likely will, from time to time be subject to change and revision.
        </ListItem>
        <ListItem>
          <b>2.9</b> During the term of this Agreement and for five (5) years after termination or expiration
          of this Agreement, not induce (or attempt to induce), solicit or encourage any employee, officer, director,
          member, manager, partner, shareholder, sales representative, agent, vendor, or independent contractor of Tax
          Guard to terminate its relationship with Tax Guard, or otherwise interfere or attempt to interfere in any way
          with Tax Guard’s relationships with any of its employees, officers, directors, members, managers, partners,
          shareholders, sales representatives, agents, vendors, independent contractors, clients, or others.
        </ListItem>
        <ListItem>
          <b>2.10</b> During the term of this Agreement and for five (5) years after termination or expiration
          of this Agreement, except as otherwise required by law, not employ or engage any third party for purposes of
          directly or indirectly competing with Tax Guard who, at any time within the twelve-month period immediately
          preceding such proposed or actual employment or engagement, was an employee, officer, director, partner,
          manager, member, shareholder, sales representative, agent, vendor, or independent contractor of Tax Guard. For
          the purposes of this Section, “employ” and “engage” shall be interpreted broadly and shall include the
          provision of services as a full or part-time employee or as a director, consultant, or other independent
          contractor.
        </ListItem>
        <ListItem>
          <b>2.11</b> Only use the Federal Tax Information for evaluating extending commercial credit. Not use
          the Federal Tax Information as a factor in establishing an individual's eligibility for (a) credit or
          insurance to be used primarily for personal, family, or household purposes, or (b) employment. In addition,
          Company will not use any Tax Guard Services to engage in any unfair or deceptive practices and will use the
          Tax Guard Services and Federal Tax Information only in compliance with applicable state, local, federal laws
          or regulations, including laws and regulations regarding privacy.
        </ListItem>
        <ListItem>
          <b>2.12</b> Consent to and expressly authorize Tax Guard to contact at any time any or all Company
          Customers as a result of its appointment under Form 8821.
        </ListItem>
        <ListItem>
          <b>2.13</b> Whenever it is legally required to obtain the consent of a Company Customer and/or its
          shareholders, officers, members, managers, directors or principals, obtain such consent in full compliance
          with all laws, statutes, and regulations and whenever it is legally required to provide notice to a Company
          Customer, and/or its shareholders, officers, members, managers, directors or principals to provide such notice
          in full compliance with all laws, statutes, and regulations.
        </ListItem>
        <ListItem>
          <b>2.14</b> Comply in all material respects with all laws, statutes, regulations, and policies
          promulgated that are or may be applicable or relate to Company’s business or its relationships and dealings
          with its customers and vendors.
        </ListItem>
        <ListItem>
          <b>2.15</b> Be responsible for maintaining the confidentiality of passwords associated with any
          account Company uses to access the Tax Guard Services. Accordingly, Company agrees that it will be solely
          responsible in all ways and manners to Tax Guard for all activities that occur under its account. If Company
          becomes aware of any unauthorized use of its password or of its account, it shall notify Tax Guard
          immediately.
        </ListItem>
        <ListItem>
          <b>2.16</b> Not misappropriate the business values of Tax Guard.
        </ListItem>
        <ListItem>
          <b>2.17</b> Use the Tax Guard portal, Federal Tax Information, Tax Guard Confidential Information,
          and all Tax Guard Services only for the purposes and in the manner expressly permitted by this Agreement and
          in accordance with all applicable laws and regulations. Company agrees to use the Tax Guard portal, the Tax
          Guard Confidential Information, and any software or trade secrets of Tax Guard only and solely for purposes of
          using the Monitoring Services in accordance with this Agreement. Company may not, and will not encourage,
          assist or authorize any other person to copy, modify, reverse engineer, decompile or disassemble, circumvent
          or bypass any technological protection measures or otherwise tamper with the Tax Guard Software, trade
          secrets, or other aspects of the Monitoring Services, whether in whole or in part, or create any derivative
          works from or of the Tax Guard Software or trade secrets.
        </ListItem>
        <ListItem>
          <b>2.18</b> Not during the term of this Agreement and for three (3) years after its termination or
          expiration directly or indirectly enter into, engage in, promote, assist (financially or otherwise), or
          consult with any business, enterprise, or activity which competes or would compete with the Monitoring
          Services of Tax Guard anywhere in the United States.
        </ListItem>

        <Heading size="md" mt={4}>Obligations of Tax Guard – Monitoring</Heading>
        <ListItem>
          <b>2.19</b> For the Company, Tax Guard will, under the 8821 Appointment, provide Monitoring
          Services. Company shall select and indicate the Monitoring Service Tax Guard shall perform for each such
          Company Client as described in the Tax Guard-provided Fee Schedule.
        </ListItem>

        <Heading size="md" mt={4}>Obligations of Tax Guard – Resolution</Heading>
        <ListItem>
          <b>2.20</b> If contacted, Tax Guard shall have the option to enter into, or decline to enter into, a
          Work Agreement. If Tax Guard declines to enter into a Work Agreement with that or any Company Customer, Tax
          Guard shall have no obligation whatsoever to that Company Customer and to Company with respect to that Company
          Customer, and Tax Guard may terminate its 8821 Appointment and thereby, if terminated, have no further
          obligation or duty whatsoever to that Company Customer or to Company as to that Company Customer. No Company
          Customer shall become a Tax Guard Resolution Services Client until entering into a Work Agreement and paying
          Tax Guard’s fee for the Tax Guard Resolution Services.
        </ListItem>
        <ListItem>
          <b>2.21</b> It is agreed that with respect to performing the Tax Guard Resolution Services, Tax
          Guard with regard to any Tax Guard Resolution Services Client reserves the right for any reason and at any
          time to withdraw from client representation if, for example, and not limitation, the Tax Guard Resolution
          Services Client fails to honor the terms of the Work Agreement, including non-payment of fees; fails to
          cooperate, to timely provide information or documentation requested by Tax Guard or follow advice on a
          material matter, or if any fact or circumstance arises or is discovered that would render continuing
          representation by Tax Guard unlawful or unethical.
        </ListItem>
      </List>

      <Heading size="md" mt={4}>3. Disclaimer of Warranties</Heading>
      <List spacing={2} mt={2} pl={6} styleType="disc">
        <ListItem>
          <Text>
            <b>3.1</b> It is specifically acknowledged by Company, with respect to all Tax Guard Resolution
            Services, the outcome of negotiations for tax resolution with federal or state taxing authorities is subject
            to factors that cannot be foreseen and are subject to many variables and legal limitations. Consequently,
            Tax Guard has made no representations to Company, express or implied, and will make no, and negates any,
            representations or predictions to Company and any Company Customer concerning the outcome of the Tax Guard
            Resolution Services. Company acknowledges that Tax Guard has not guaranteed, cannot guarantee, and negates
            any guarantee to Company or to any Tax Guard Resolution Services Client of the success of any action taken
            by Tax Guard on behalf of any Tax Guard Resolution Services Client with respect to the performance of the
            Tax Guard Resolution Services or that a particular agreement, the terms of an agreement or financial
            arrangements will be obtained which are satisfactory to the Tax Guard Resolution Services Client. Tax Guard
            makes no guarantee or representation, and specifically negates any guarantee or representation that it can
            prevent a levy by the federal or state taxing authorities of any Tax Guard Resolution Services Client.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>3.2</b> If Company uses the Monitoring Services, except as may otherwise expressly set forth
            herein, Company is responsible for maintaining the confidentiality of its account and password and for
            restricting access to Company’s computers consistent with its business needs and Company agrees to accept
            responsibility for all activities that occur under its account or password.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>3.3</b> COMPANY AGREES THAT ITS USE OF THE WEBSITE IS AT ITS SOLE RISK. BECAUSE OF THE INHERENT
            HAZARDS, CORRUPTION, AND UNCERTAINTIES OF ELECTRONIC DISTRIBUTION, THERE MAY BE INTERRUPTIONS, DELAYS,
            OMISSIONS, INACCURACIES, OR OTHER PROBLEMS WITH THE WEBSITE AND ITS APPLICATIONS. IF COMPANY RELIES ON THE
            WEBSITE OR ANY MATERIAL AVAILABLE THROUGH IT, IT DOES SO AT ITS OWN RISK. COMPANY UNDERSTANDS THAT IT IS
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO ITS COMPUTER SYSTEMS OR LOSS OF DATA THAT RESULTS FROM ANY MATERIAL
            AND/OR DATA DOWNLOADED FROM OR OTHERWISE PROVIDED THROUGH THE WEBSITE. THE WEBSITE AND MONITORING SERVICES
            ARE PROVIDED "AS IS," "WITH ALL FAULTS," AND "AS AVAILABLE." TAX GUARD AND ITS AGENTS CANNOT AND DO NOT
            WARRANT THE ACCURACY, COMPLETENESS, USEFULNESS, TIMELINESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR
            A PARTICULAR PURPOSE OF THE INFORMATION AVAILABLE THROUGH THE WEBSITE AND THE MONITORING SERVICES, NOR DO
            THEY GUARANTEE THAT THE WEBSITE AND THE MONITORING SERVICES WILL BE ERROR-FREE, OR CONTINUOUSLY AVAILABLE,
            OR THAT THE WEBSITE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>3.4</b> The Federal Tax Information is obtained from the Internal Revenue Service. Tax Guard
            cannot and does not guarantee or warrant that the Federal Tax Information obtained from the Internal Revenue
            Service is complete, accurate, adequate, or current. As a result, Tax Guard does not give any express or
            implied warranties OF ACCURACY, COMPLETENESS (INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY OR
            FITNESS FOR A PARTICULAR USE) WITH RESPECT TO THE FEDERAL TAX INFORMATION, OR THE USE THEREOF and disclaims
            all such representations and warranties regarding the performance of the Tax Guard Services, the accuracy,
            adequacy, currency, or completeness of any Federal Tax Information therein.
          </Text>
        </ListItem>
      </List>

      <Heading size="md" mt={4}>4. Limitation of Liability/ Limitation of Action</Heading>
      <List spacing={2} mt={2} pl={6} styleType="disc">
        <ListItem>
          <Text>
            <b>4.1</b> Except as otherwise expressly set forth in this Agreement, Tax Guard (and its agents,
            employees, officers, shareholders, directors, and representatives) shall not under any circumstances,
            including but not limited to negligence or breach of contract, be liable to Company or any third party for
            any loss, damage, injury, claim, liability, or costs whatsoever including special, punitive, exemplary,
            consequential, indirect, incidental, or direct damages whatsoever (including, but not limited to damages for
            loss of business, goodwill, use, cost of procurement or substitute services, any intangible loss, loss of
            profits, opportunity or investment, or the like) (all of the foregoing "Damages"), caused in whole or in
            part by the failure to meet any obligation or duty, including good faith or reasonable care, or negligence,
            regardless of the form of action, whether based on this Agreement, breach of contract, breach of warranty,
            strict liability, statute, or tort (including negligence) or otherwise, even if Tax Guard (its agents,
            officers, shareholders, directors, agents, employees, or representatives) has been advised of the
            possibility of such Damages and even if a remedy set forth herein is found to have failed of its essential
            purpose. By way of example and not limitation, this limitation includes, but is not limited to, all Damages
            caused in whole or in part by Tax Guard pursuant to the 8821 Appointment or the Tax Guard Services in (a)
            procuring, compiling, and collecting any of the Federal Tax Information, (b) interpreting, reporting,
            transmitting, or delivering the Federal Tax Information to the Company, (c) actions or omissions of any
            third parties, (d) the performance of any of the Tax Guard Services, (e) the failure of the Company to
            fulfill any of its obligations under Article 2 hereof, (f) the use of the Website or (g) the transmission of
            any viruses which may infect the user’s equipment, failure of mechanical or electronic equipment or
            communication lines, or other interconnection problems.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>4.2</b> The Section 4.1 limitations and exclusions apply to the fullest extent permitted by
            applicable law. To the maximum extent permitted by law, Company and Tax Guard expressly intend and agree if
            there is liability and to the extent the foregoing limitation of liability is prohibited or fails of its
            essential purpose, the maximum amount and the entire aggregate liability of Tax Guard its agents, employees,
            officers, shareholders, directors, and representatives to Company and all third parties shall be
            cumulatively limited to the lesser of one (1) month of the lowest Monthly Fee actually paid by Company
            pursuant to this Agreement, or $5,000.00, and this liability shall be exclusive.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>4.3</b> THE REMEDIES SET FORTH IN THIS ARTICLE 4 OR TERMINATION OF THIS AGREEMENT ARE COMPANY’S
            EXCLUSIVE REMEDIES FOR CLAIMS OR DAMAGES ARISING OUT OF OR RELATING IN ANY WAY TO THIS AGREEMENT, THE USE OF
            THE WEBSITE OR TAX GUARD SERVICES OR FOR ANY INFORMATION OR SERVICES PROVIDED HEREUNDER. EXCEPT FOR A BREACH
            BY TAX GUARD OF ITS OBLIGATIONS OF CONFIDENTIALITY OR PROTECTION OF Confidential Tax Information AND Company
            Confidential Information AS SET FORTH UNDER THIS AGREEMENT; AS A RESULT OF AND ONLY TO THE EXTENT OF TAX
            GUARD’S FRAUD, GROSS NEGLIGENCE, WILLFUL MISCONDUCT, COMPANY AGREES THAT TAX GUARD’S LIABILITY FOR ANY OTHER
            CLAIM OF ANY KIND RELATING TO THIS AGREEMENT, THE WEBSITE OR THE TAX GUARD SERVICES SHALL BE GOVERNED BY
            SECTION 4.1 AND NEVER EXCEED THE AMOUNT SET FORTH IN SECTION 4.2.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>4.4</b> Except as may be prohibited by pertinent law, no action arising out of this Agreement
            or otherwise for any reason, regardless of form or type of claim may be brought by Company (and Tax Guard’s
            liability shall terminate) after the earlier of one (1) year after the date of the last delivery of
            Monitoring Services to Company or one (1) year after the cause of action accrued.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>4.5</b> The provisions of this Article 4 reflect an expressly agreed-upon and accepted
            allocation of risk and shall apply equally to each of the Tax Guard Services. The Monthly Fee charged by Tax
            Guard is set to reflect this allocation of risk.
          </Text>
        </ListItem>
      </List>

      <Heading size="md" mt={4}>5. Term and Termination</Heading>
      <List spacing={2} mt={2} pl={6} styleType="disc">
        <ListItem>
          <Text>
            <b>5.1</b> The term of this Agreement shall commence on the date Company accepts this Agreement
            and shall continue until terminated in accordance with this Article 5.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>5.2</b> Either Party may terminate this Agreement at any time on sixty (60) days written notice
            to the other Party.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>5.3</b> Tax Guard may terminate this Agreement immediately upon notice from Tax Guard to
            Company if: (a) Company fails to timely make payment as required under this Agreement of the Monthly Fee; or
            (b) Company, in the opinion of Tax Guard, which upon shall be conclusive, breaches any of the provisions of
            this Agreement.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>5.4</b> Upon the effective date of termination of this Agreement, regardless of the reason, Tax
            Guard shall terminate Monitoring Services, Company shall lose access to the Website, and neither party shall
            thereafter have any further rights, duties, or obligations hereunder except for obligations and duties under
            (a) Articles 3, 4, 6, 8, and 9, Sections 2.6-2.12, inclusive (b) any then vested right or cause of action
            which has accrued prior to the effective date of termination, and (c) provisions of this Agreement which
            would require that they survive the termination of the Agreement in whole or in part in order to give them
            full force and effect, all of the foregoing to survive termination regardless of the date, cause, or manner
            of such termination. Without limiting the foregoing, Company shall remain liable to Tax Guard for the
            Monthly Fee rendered to Company prior to the effective date of termination for which payment has not
            previously been made. Upon termination, Tax Guard shall terminate all 8821 Appointments with respect to
            Company Customers, although Tax Guard shall continue to fulfill Tax Guard Resolution Services to Tax Guard
            Resolution Service Clients.
          </Text>
        </ListItem>
      </List>

      <Heading size="md" mt={4}>6. Restrictive Covenants</Heading>
      <Text mt={2}>
        <b>“Tax Guard Confidential Information”</b> means all information or knowledge that is the property
        of, used by or in the possession of Tax Guard, whether oral, written, electronic or in any other medium, of a
        competitive value to Tax Guard, which is not generally known to others engaged in similar businesses which
        relates, is connected to or arises from Tax Guard’s business, present or future, which includes by way of
        example and not limitation, its business operations, methodologies, computer software, computer systems,
        computer programs, trade secrets, marketing plans, pending patents, inventions, algorithms, development plans,
        business plans, employee personal files, customer files, manuals, financial information, tax returns, non-public
        policies, business strategies, printed or written materials and contracts, documents (including forms or
        templates) generated or used in connection with Tax Guard’s business, customer lists, the identity of Tax
        Guard’s customers, and the nature of fees and charges made to Tax Guard’s customers without regard as to whether
        any of such Tax Guard Confidential Information may be deemed confidential or material to any third party, and
        Company and Tax Guard stipulate to the confidentiality and materiality of such Confidential Information.
      </Text>
      <Text mt={2}>
        <b>"Company Confidential Information”</b> means Company’s list of Company Clients, its former Company
        Clients and all non-public information relating to and identified with such persons/entities. Company
        Confidential Information does not include information which is or later becomes publicly available through no
        fault of or with no assistance or cooperation from Tax Guard or is information disclosed to Tax Guard by a third
        party who is independent from Company, without an obligation of secrecy or confidentiality imposed upon such
        third party.
      </Text>
      <Text mt={2}>
        <b>"Confidential Tax Information"</b> means all information, knowledge or data whether written, oral,
        electronic or in any other medium with respect to a Company Customer that is developed, collected and compiled
        by Tax Guard in the course of providing the Tax Guard Services (a) is nonpublic and is in connection with, about
        or relating to its Federal Tax Information, (b) is the subject of privacy protections or nondisclosure
        restrictions under any federal or state statute or law or (c) was provided to Company by Tax Guard by virtue of
        Form 8821.
      </Text>
      <List spacing={2} mt={2} pl={6} styleType="disc">
        <ListItem>
          <Text>
            <b>6.1</b> Tax Guard shall not at any time after the date of this Agreement, directly or
            indirectly, copy, disseminate or use, for its personal benefit or the benefit of any third party except
            Company, any Company Confidential Information or Confidential Tax Information regardless of how such
            information may have been acquired, except for the disclosure or use of such Company Confidential
            Information or Confidential Tax Information as may be (a) required by law or by court or administrative
            order (but only to the extent so required), (b) reasonably necessary or required in order to conduct the
            business of Tax Guard or to otherwise carry out and perform its duties and Tax Guard Services hereunder
            which includes providing and delivering such information to Company as part of the Monitoring Services.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>6.2</b> Tax Guard agrees it shall not instruct any Company Client to terminate its relationship
            with Company, provided, however, this provision shall not preclude Tax Guard from recommending that a Tax
            Guard Resolution Services Client engage or attempt to engage the services of any other company or business
            in competition with Company at such time Company ceases, for any reason, to provide lending services to a
            Tax Guard Resolution Services Client which cessation shall be first confirmed in writing to Tax Guard by the
            Company. No confirmation shall be necessary where a Prospective Company Client does not become a Company
            Client.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>6.3</b> Company shall not at any time after the date of this Agreement, directly or indirectly,
            copy, disseminate or use, in any way whatsoever for its personal benefit or the benefit of any third party,
            any Confidential Tax Information or Tax Guard Confidential Information regardless of how such information
            may have been acquired, except for the disclosure or use of such Confidential Tax Information or Tax Guard
            Confidential Information as may be (a) required by law or by court or administrative order (but only to the
            extent so required), or (b) reasonably necessary or required in order to conduct the business of Company or
            in strict conjunction with this Agreement and solely for the limited purposes for which it was disclosed to
            Company by Tax Guard and agreed to by a Company Client. Company agrees to implement and maintain
            commercially reasonable measures and policies to physically protect all Confidential Tax Information and Tax
            Guard Confidential Information and be certain that only its employees who have a bona fide need to know for
            the Company’s Business Purpose receive the Confidential Tax Information or Tax Guard Confidential
            Information.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>6.4</b> The Parties acknowledge that any breach of any of these obligations of confidentiality
            shall result in irreparable harm in an amount not easily ascertained. Therefore, each Party agrees that any
            breach of any of these obligations of confidentiality will give the damaged Party the right to terminate
            this Agreement immediately, obtain an injunction enjoining or restraining the disclosure or use of any of
            the aforesaid confidential information, without the requirement of posting a bond or other security, and the
            right to pursue all other remedies that the damaged party may have at law or in equity.
          </Text>
        </ListItem>
      </List>

      <Heading size="md" mt={4}>7. Marketing Use</Heading>
      <Text>
        Tax Guard may include Company’s name in Tax Guard communications/marketing efforts and shall be permitted to
        disclose or otherwise publicize its relationship with Company as part of any of its marketing. Tax Guard will
        review all language with Company prior to publicizing and thereby providing Company an opportunity for review,
        comment, and prior consent.
      </Text>

      <Heading size="md" mt={4}>8. General Provisions</Heading>
      <List spacing={2} mt={2} pl={6} styleType="disc">
        <ListItem>
          <Text>
            <b>8.1</b> In case any term or provision of this Agreement shall be invalid, illegal, or
            unenforceable, in whole or in part, the portion that is determined to be invalid shall be severed, and the
            validity of any of the other terms or provisions of this Agreement shall not in any way be affected thereby.
            Nothing contained in this Agreement shall be construed as requiring the commission of any act contrary to
            law. In the event there is any conflict between any provision of this Agreement and any statute, law,
            ordinance, or regulation contrary to which the Parties hereto have no legal right to contract, the latter
            shall prevail. In event of conflict, the provisions of this Agreement thus affected shall be curtailed and
            limited only to the extent necessary to cause this Agreement to conform to said requirement of law. The
            Parties agree that, if the scope or enforceability of the Section 2.7, 2.9, 2.10, 2.16, 2.18 and Article 6
            covenants, or any parts thereof, are in any way disputed at any time, a court may modify and enforce the
            particular covenant to the extent that it believes the covenant is reasonable and enforceable under all of
            the circumstances existing at the time.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>8.2</b> This Agreement shall be governed by and construed in accordance with the laws of the
            State of Colorado, without regard to principles of conflicts of law. Company and Tax Guard irrevocably
            submit to the exclusive jurisdiction of the State of Colorado (state or federal), with court venue in
            Broomfield County (and arbitration venue in Denver County), over any Dispute (as defined hereinafter).
            Company and Tax Guard irrevocably waive, to the fullest extent permitted by applicable Law, any objection
            that they may have to the venue of any such dispute brought in the county set forth herein in Colorado, any
            defense of inconvenient forum for the maintenance of such dispute or any defense of lack of personal
            jurisdiction.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>8.3</b> This Agreement together constitutes the exclusive and entire agreement of the Parties
            relating to the subject matter hereof, and there are no written or oral terms or representations made by
            either Party other than those contained herein. This Agreement supersedes and replaces any and all
            agreements of any kind, whether written or oral, express or implied with respect to the subject matter, all
            of which shall be terminated upon the acceptance of this Agreement. Any unilateral terms or conditions on
            any materials that Company regularly uses will be null and void and of no consequence whatsoever in
            interpreting the Parties’ legal rights and responsibilities as they pertain to the Tax Guard Services. This
            Agreement and/or the Monitoring Services may not be assigned by the Company. Tax Guard may freely assign its
            rights and obligations under this Agreement with or without notice to Company.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>8.4</b> Company’s use of the Website and the Tax Guard Services includes the ability to enter
            into agreements and/or to make transactions electronically. COMPANY ACKNOWLEDGES THAT ITS ELECTRONIC
            SUBMISSIONS CONSTITUTES ITS AGREEMENT AND INTENT TO BE BOUND BY SUCH AGREEMENTS AND TRANSACTIONS. COMPANY’S
            AGREEMENT AND INTENT TO BE BOUND BY ELECTRONIC SUBMISSIONS APPLIES TO ALL RECORDS RELATING TO ALL
            TRANSACTIONS COMPANY ENTERS INTO WITH TAX GUARD, INCLUDING NOTICES OF CANCELLATION, POLICIES, CONTRACTS, AND
            APPLICATIONS.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>8.5</b> Tax Guard is not engaged in rendering legal, tax advice or accounting services Company
            acknowledges and agrees that the Tax Guard Services and the Website are not intended to be and will not be
            relied upon by Company as either legal, financial or tax advice and no attorney-client relationship is
            formed.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>8.6</b> No termination or expiration of this Agreement affects or impairs any obligations,
            duties, indemnities, and liabilities of either Party that, by their nature, continue beyond termination, or
            the rights of Tax Guard relating to any unpaid obligations of Company.
          </Text>
        </ListItem>
      </List>

      <Heading size="md" mt={4}>9. Arbitration</Heading>
      <Text mt={2}>
        PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS RIGHTS THAT YOU MAY OTHERWISE HAVE. IT PROVIDES FOR RESOLUTION OF
        DISPUTES THROUGH MANDATORY ARBITRATION WITH A FAIR HEARING BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY
        A JUDGE OR JURY OR THROUGH A CLASS ACTION OR REPRESENTATIVE PROCEEDING. THE ENFORCEABILITY OF THIS AGREEMENT IS
        GOVERNED BOTH PROCEDURALLY AND SUBSTANTIVELY BY FEDERAL AND COLORADO LAW, TO THE MAXIMUM EXTENT PERMITTED BY
        LAW.
      </Text>
      <List spacing={2} mt={2} pl={6} styleType="disc">
        <ListItem>
          <Text>
            <b>(a) Arbitration Terms.</b> Company agrees, except as otherwise set forth herein, that all disputes or
            claims arising out of or in any way relating to this Agreement or is in any way connected to the
            interpretation, application or enforcement of this Agreement, the relationship between Tax Guard and Company
            or any matter whatsoever between Tax Guard (and its employees, officers, directors, shareholders,
            representatives, heirs, successors, attorneys and other agents) and Company based on any legal theory will
            be resolved by binding arbitration (a “Dispute”).
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>(b) Arbitration Procedures.</b> Before commencing arbitration Company must first present any Dispute to
            Tax Guard in writing to allow Tax Guard the opportunity to resolve the Dispute. If the Dispute is not
            resolved within 60 days, Company may request arbitration by serving a completed Demand for Arbitration (the
            “Demand”). The Demand shall state plainly and clearly (a) a detailed description of the nature of and the
            facts underlying the Dispute (b) the legal and/or contractual basis of the Dispute including but not limited
            to the specific authority from which the Dispute arises; and (c) the specific relief, and/or proposed
            solution or remedy sought.
            The arbitration shall be conducted by the Judicial Arbiter Group in Denver, CO (“JAG”) in accordance with
            its Commercial Arbitration Rules of the American Arbitration Association (“AAA,” but shall not be conducted
            by the AAA). There shall be one arbitrator who will be a former judge and will have at least 10 years of
            legal experience in the resolution of commercial disputes. The arbitrator shall be chosen by written mutual
            agreement of the parties. If, after 7 days, Company and Tax Guard are unable to agree upon an arbitrator,
            JAG will appoint the arbitrator. The arbitrator shall apply the substantive law of the state of Colorado,
            without giving effect to its conflict of law provisions. All face-to-face proceedings shall take place in
            Denver in the State of Colorado.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>(c) Award.</b> Arbitration is final, non-appealable, and binding. In making any award, the arbitrator
            shall be restricted by the “Limitation of Liability” provision in this Agreement and shall not have
            jurisdiction to make an award to any party to the arbitration contrary to the “Limitation of Liability”
            provision in Article 4 and shall adhere to the time limitation in Section 4.4. Company expressly agrees that
            the rulings of the arbitrator, including any award, shall be binding, non-reviewable and non-appealable and
            it may be entered in any court of competent jurisdiction.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>(d) Confidentiality.</b> Any arbitration shall be confidential, and neither Company nor Tax Guard may
            disclose the existence, content or results of any arbitration, except as may be required by law or for
            purposes of enforcement of the arbitration award. Any Party shall have the right to prevent any actual or
            threatened breach of this confidentiality provision by temporary, preliminary or permanent injunctive or
            declaratory relief.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>(e) Costs of Arbitration.</b> The party requesting arbitration must pay the applicable JAG fee. Each
            party shall pay its own expenses of the arbitration, including the expense of its own counsel, witnesses,
            and presentation of evidence at the arbitration. If any party files a judicial or administrative action
            asserting a claim or Dispute that is subject to arbitration and another party successfully stays such action
            or compels arbitration, the party filing that action must pay the other party’s costs and expenses incurred
            in seeking such stay or compelling arbitration, including reasonable attorneys’ fees.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>(f) Waiver of Jury and Class Action and Other Representative Proceeding.</b> THE PARTIES EXPRESSLY AGREE
            THAT THERE SHALL BE NO JURY OR COURT TRIAL OR RIGHT TO A JURY OR COURT TRIAL, OR RIGHT TO ANY OTHER
            PROCEEDING TO RESOLVE ANY DISPUTE IN ANY COURT. THE PARTIES ALSO EXPRESSLY AGREE THAT ANY DISPUTE IS
            PERSONAL TO THEM, AND ANY SUCH DISPUTE SHALL ONLY BE RESOLVED BY AN INDIVIDUAL ARBITRATION. BOTH PARTIES
            AGREE THERE CAN BE NO CLASS ARBITRATION OR ARBITRATION WHERE A PERSON BRINGS A DISPUTE AS A REPRESENTATIVE
            OF ANY OTHER PERSON OR PERSONS. BOTH PARTIES AGREE THAT A DISPUTE CANNOT UNDER ANY CIRCUMSTANCES BE BROUGHT
            AS A CLASS OR REPRESENTATIVE ACTION OUTSIDE OF ARBITRATION, OR ON BEHALF OF ANY OTHER PERSON OR PERSONS.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <b>(g) Matters excluded from mandatory arbitration.</b> Excluded from a Dispute and not within the scope of
            mandatory binding arbitration (and which may be litigated in court) are claims to restrain or seek specific
            performance of Sections 2.9, 2.10, 2.16, 2.17, 2.18 and 6.3. Tax Guard and Company acknowledge and agree
            that Tax Guard’s remedies at law for any violation or attempted violation of any of Company’s obligations
            under Sections 2.9, 2.10, 2.16, 2.17, 2.18, 6.3 and the confidentiality provision in 9d would be inadequate,
            and agree that in the event of any such violation or attempted violation, Tax Guard shall each be entitled
            to a temporary restraining order, temporary and permanent injunctions, and other equitable relief, without
            the necessity of posting any bond or proving any actual damage, in addition to all other rights, damages,
            and remedies that may be available to Tax Guard from time to time.
          </Text>
        </ListItem>
      </List>
    </Box>
  );
}

export default TermsOfService;
