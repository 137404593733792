import React, { useState } from 'react';
import {
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay
} from '@chakra-ui/react';
import Select from 'react-select';
import { getPastMonthsList } from '../../../../libraries/dateFilterOptions';

interface BankStatementModalProps {
	isLoading: boolean;
	isOpen: boolean,
	onClose: () => void,
	accounts: any,
	onDownload: (accountId: string, index: number) => Promise<void>
}

const InvestmentStatementModal: React.FC<BankStatementModalProps> = ({
																																			 isLoading,
																																			 isOpen,
																																			 onClose,
																																			 onDownload,
																																			 accounts
																																		 }) => {
	const [errorFlag, setErrorFlag] = useState<boolean>(false);
	const [selectedAccount, setSelectedAccount] = useState<{ value: string, label: string, isStatementAvailable: boolean, isManual: boolean } | null>(null);
	const twoYearsOfMonthsList = getPastMonthsList(2);
	const [selectedIndex, setSelectedIndex] = useState<{ value: number, label: string } | null>(twoYearsOfMonthsList[0]);

	const download = async () => {
		if (!selectedAccount) {
			setErrorFlag(true);
		} else {
			await onDownload(selectedAccount ? selectedAccount.value : '', selectedIndex?.value || 1);
			onClose();
			setSelectedAccount(null);
		}
	}

	const handleClose = () => {
		onClose();
		setSelectedAccount(null);
		setErrorFlag(false);
	}

	return (
		<Modal isOpen={isOpen} onClose={() => handleClose()} isCentered>
			<ModalOverlay/>
			<ModalContent>
				<ModalHeader>Download Statement</ModalHeader>
				<ModalCloseButton/>
				<ModalBody>
					<FormControl isRequired>
						{errorFlag ? (
							<FormHelperText color="red">
								{!selectedAccount ? 'Account is required' : ''}
							</FormHelperText>
						) : null}
						<FormLabel>Account Name</FormLabel>
						<Select
							isLoading={isLoading}
							value={selectedAccount}
							placeholder="Select an account name"
							onChange={setSelectedAccount}
							isOptionDisabled={(option) => !option.isManual && !option.isStatementAvailable}
							options={accounts}
						/>
					</FormControl>
					<FormControl mt="12px">
						<FormLabel>Month</FormLabel>
						<Select
							isLoading={isLoading}
							value={selectedIndex}
							isDisabled={selectedAccount?.isManual}
							placeholder="Select a month"
							onChange={setSelectedIndex}
							options={twoYearsOfMonthsList}
						/>
					</FormControl>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme="brandScheme" mr={3} onClick={() => download()} isLoading={isLoading} loadingText='Downloading...'>Download</Button>
					<Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default InvestmentStatementModal;
