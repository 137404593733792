import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../auth-context/auth.context';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import Card from "../../../components/card/Card";
import { MdAdd, MdContentCopy, MdDownload, MdEdit, MdOpenInNew } from "react-icons/md";
import { HelpIcon } from "../../../components/icons/Icons";
import { debounce } from "lodash";
import { downLoadQrCode, generateQrCode, getSmartleadInfo, isValidLinkSlug } from "../../../api/user-service";
import { HexColorPicker } from "react-colorful";
import useClickOutside from "../../../libraries/useClickOutside";
import { ThreeDots } from "react-loader-spinner";
import SnippetModal from "../../admin/modals/components/SnippetModal";

const { REACT_APP_SMARTLEAD_BASE_URL = '' } = process.env;


const SmartLeads: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();
  const textColorSecondary = 'gray.500';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [copyBtnText, setCopyBtnText] = useState('Copy Link');
  const [label, setLabel] = useState('');
  const [tempLabel, setTempLabel] = useState('');
  const [eyeColor, setEyeColor] = useState('#000000');
  const [isOpenEyeColorPicker, setIsOpenEyeColorPicker] = useState(false);
  const [eyeBallColor, setEyeBallColor] = useState('#000000');
  const [isOpenEyeBallColorPicker, setIsOpenEyeBallColorPicker] = useState(false);
  const [tempSlug, setTempSlug] = useState('');
  const [tempEyeColor, setTempEyeColor] = useState('#000000');
  const [tempEyeBallColor, setTempEyeBallColor] = useState('#000000');
  const [slug, setSlug] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSnippetOpen, onOpen: onSnippetOpen, onClose: onSnippetClose } = useDisclosure();
  const [errorSlugMessage, setErrorSlugMessage] = useState('');
  const [iconUrl, setIconUrl] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [brandColor, setBrandColor] = useState('#000000');
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const { onCopy } = useClipboard(`${REACT_APP_SMARTLEAD_BASE_URL}/s/${slug}`);

  const eyePopOver = useRef<any>();
  const eyeBallPopOver = useRef<any>();

  const closeEyeColorPicker = useCallback(() => setIsOpenEyeColorPicker(false), []);
  const closeEyeBallColorPicker = useCallback(() => setIsOpenEyeBallColorPicker(false), []);
  useClickOutside(eyePopOver, closeEyeColorPicker);
  useClickOutside(eyeBallPopOver, closeEyeBallColorPicker);

  useEffect(() => {
    setIsLoading(true);
    getSmartleadInfo()
      .then(({ info }) => {
        setLabel(info.label);
        setSlug(info.slug);
        setEyeColor(info?.eyeColor || '#000000');
        setEyeBallColor(info?.eyeBallColor || '#000000');
        setQrCodeUrl(info.qrCodeUrl);
        setIconUrl(info?.iconUrl || '');
        setLogoUrl(info?.logoUrl || '');
        setBrandColor(info?.brandColor || '#000000');
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  const handleCopyClick = () => {
    onCopy();
    setCopyBtnText('Copied!');
    setTimeout(() => {
      setCopyBtnText('Copy Link');
    }, 1000);
  };

  const getExtensionFromUrl = (url: string) => {
    return url.split('.').pop()?.split(/\#|\?/)[0] ?? '';
  }

  const handleDownloadClick = async () => {
    try {
      const { url } = await downLoadQrCode();
      const extension = getExtensionFromUrl(qrCodeUrl);
      const response = await fetch(url);
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${label}-QR.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.log('Failed to download image');
      }
    } catch (error) {
      console.log('Failed to download image', error);
    }
  }

  const handleSlugChange = ((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let validSlug = value
      .replace(/[^a-zA-Z0-9-_]/g, '')
      .replace(/(-_)+/g, '-');

    setTempSlug(validSlug);
    debounced(validSlug);
  });

  function onChangeHexColor(e: ChangeEvent<HTMLInputElement>, isBgColor?: boolean) {
    const { value } = e.target;

    const hexColorRegex = /^#[0-9A-Fa-f]*$/;

    if (hexColorRegex.test(value)) {
      if (isBgColor) {
        setTempEyeColor(value);
      } else {
        setTempEyeBallColor(value);
      }
    }
  }

  const handleConfirmClick = async () => {
    try {
      setIsGenerating(true);
      setLabel(tempLabel);
      setSlug(tempSlug);
      setEyeColor(tempEyeColor);
      setEyeBallColor(tempEyeBallColor);

      const { data } = await generateQrCode(tempLabel, tempSlug, tempEyeColor, tempEyeBallColor);
      setQrCodeUrl(data.linkQrcodeUrl);

      setIsGenerating(false);
      onClose();
    } catch (e) {
      toast({
        title: 'Cannot generate the Link and QR Code. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsGenerating(false);
    }
  }

  async function validateSlug(slug: string) {
    try {
      await isValidLinkSlug(slug);
      setErrorSlugMessage('');
    } catch (err: any) {
      setErrorSlugMessage(err?.response?.data?.message || 'Invalid link')
    }
  }

  const debounced = useCallback(debounce(validateSlug, 500), []);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

      <VStack>
        <Card
          direction="column"
          w="100%"
          px="0"
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
          <Flex align={{ base: 'start', md: 'center' }} px='20px' justify='space-between'
                direction={{ base: 'column', md: 'row' }} gap={2}>
            <Text fontSize='xl' fontWeight='500'>Your Link & QR Code</Text>
            <HStack justify='end' w={{ base: 'full', md: 'auto' }}>
              <Button colorScheme='navy' variant='solid' size='sm' isDisabled={!user || !slug}
                      onClick={onSnippetOpen}>
                Website Widget
              </Button>
              <Button colorScheme='navy' variant='ghost' leftIcon={slug ? <MdEdit /> : <MdAdd />} size='sm'
                      isDisabled={!user}
                      onClick={() => {
                        onOpen();
                        setTempLabel(label ? label : `${user?.fullName} - ${user?.firstName}`);
                        setTempEyeColor(eyeColor);
                        setTempEyeBallColor(eyeBallColor ? eyeBallColor : brandColor);
                        setTempSlug(slug ? slug : user?.sub);
                      }}>
                {slug ? 'Edit' : 'Generate'} Link/QR
              </Button>
            </HStack>
          </Flex>
          <Divider my='20px' />
          {
            isLoading ? <Center>
              <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#4fa94d"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Center> : <Box>
              <Box px='20px'>
                <HStack>
                  <Text fontSize='lg' fontWeight='500'>Firm Name:</Text>
                  <Text fontSize='lg' fontWeight='light' color={textColorSecondary}>{user?.fullName}</Text>
                </HStack>
                <Flex my='12px' direction={{ base: 'column', md: 'row' }} justify='space-around' gap={4}>
                  <VStack align='start'>
                    <HStack>
                      <Text fontWeight='400'>Logo Icon</Text>
                      <Tooltip
                        label="A smaller version of your logo (such as a favicon). It will show up on the connect link screen"
                        hasArrow placement="top">
                        <HelpIcon />
                      </Tooltip>
                    </HStack>
                    <Image
                      src={iconUrl ? `${iconUrl}?cb=${new Date().getTime()}` : require("../../../assets/img/unknown-logo-icon.png")}
                      h='48px'
                      w='auto'
                      objectFit='contain'
                      alt='firm icon' />
                  </VStack>
                  <VStack align='start'>
                    <HStack>
                      <Text fontWeight='400'>Firm Logo</Text>
                      <Tooltip label="The full-size version of your logo" hasArrow placement="top">
                        <HelpIcon />
                      </Tooltip>
                    </HStack>
                    <Image
                      src={logoUrl ? `${logoUrl}?cb=${new Date().getTime()}` : require("../../../assets/img/unknown-company-logo.png")}
                      h='48px'
                      w='auto'
                      objectFit='contain'
                      alt='firm logo' />
                  </VStack>
                  <VStack align='start'>
                    <HStack>
                      <Text fontWeight='400'>Brand color</Text>
                      <Tooltip label="Brand color is used as active color of your connect link screen and QR code."
                               hasArrow
                               placement="top">
                        <HelpIcon />
                      </Tooltip>
                    </HStack>
                    <InputGroup>
                      <InputLeftElement bg={brandColor} borderRadius='8px' />
                      <Input pl={12} readOnly defaultValue={brandColor} />
                    </InputGroup>
                  </VStack>
                </Flex>
                <Text fontSize='md' mt='8px' color={textColorSecondary}>
                  * If you are a firm owner, you can upload your firm icon and logo for your link. Visit Settings / Firm
                  tab.</Text>
              </Box>
              <Box px='20px'>
                <Divider my='20px' />
                <Text fontSize='lg' fontWeight='500'>Label Name</Text>
                <Text fontSize='lg' fontWeight='light'
                      color={textColorSecondary}>{label ? label : 'Not configured yet!'}</Text>

                <Divider my='20px' />

                <Text fontSize='lg' fontWeight='500'>Link URL</Text>
                <HStack align='center'>
                  <Input readOnly value={`${REACT_APP_SMARTLEAD_BASE_URL}/s/${slug || ''}`} />
                  <Button colorScheme='brand' variant='outline' leftIcon={<MdOpenInNew />} isDisabled={!slug}
                          onClick={() => window.open(`${REACT_APP_SMARTLEAD_BASE_URL}/s/${slug}`, '_blank')}>
                    Open
                  </Button>
                </HStack>
                <Button mt={2} colorScheme='green' leftIcon={<MdContentCopy />} onClick={handleCopyClick} size='sm'
                        isDisabled={!slug}>
                  {copyBtnText}
                </Button>

                <Divider my='20px' />
                <Text fontSize='lg' fontWeight='500'>QR Code</Text>
                <Center mb='20px'>
                  {
                    qrCodeUrl ? <Image
                      src={`${qrCodeUrl}?cb=${new Date().getTime()}`}
                      w={48}
                      h={48}
                      alt='QR Code'
                    /> : <Text color={textColorSecondary}>There is no QR Code yet. Generate a new one!</Text>
                  }
                </Center>
                <Button mt={2} colorScheme='green' leftIcon={<MdDownload />} onClick={handleDownloadClick} size='sm'
                        isDisabled={!qrCodeUrl}>
                  Download QR Code
                </Button>
              </Box>
            </Box>
          }
        </Card>
      </VStack>

      {/* Generate Link Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size='4xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{slug ? 'Edit' : 'Generate'} Link/QR Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColorSecondary}
                display="flex"
              >
                Label Name
              </FormLabel>
              <Input
                fontSize="sm"
                focusBorderColor='brand.500'
                value={tempLabel}
                onChange={e => setTempLabel(e.target.value)}
                type='text'
              />
            </FormControl>
            <Divider my='20px' />
            <FormControl isInvalid={!!errorSlugMessage}>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColorSecondary}
                display="flex"
              >
                Link URL
              </FormLabel>
              <InputGroup>
                <InputLeftAddon pointerEvents='none' color='gray.500' bg='gray.200' borderLeftRadius='8px'>
                  <Text fontWeight='500'>{REACT_APP_SMARTLEAD_BASE_URL}/s/</Text>
                </InputLeftAddon>
                <Input
                  fontSize="sm"
                  focusBorderColor='brand.500'
                  value={tempSlug}
                  onChange={e => handleSlugChange(e)}
                  type='text'
                />
              </InputGroup>
              <FormErrorMessage>{errorSlugMessage}</FormErrorMessage>
            </FormControl>
            <Divider my='20px' />
            <Grid templateColumns='repeat(2, 1fr)' gap={4}>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColorSecondary}
                    display="flex"
                  >
                    QR Code - Bg Color
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      bg={isOpenEyeColorPicker ? tempEyeColor : eyeColor}
                      borderRadius='8px'
                      cursor={user?.firmRole !== 'admin' ? 'not-allowed' : 'pointer'}
                      onClick={user?.firmRole !== 'admin' ? () => {} : () => setIsOpenEyeColorPicker(prev => !prev)}
                    />
                    <Input pl={12} value={isOpenEyeColorPicker ? tempEyeColor : eyeColor}
                           onChange={e => onChangeHexColor(e, true)} maxLength={7}
                           disabled={user?.firmRole !== 'admin'} />
                  </InputGroup>
                  {
                    isOpenEyeColorPicker && (
                      <Box mt={4} ref={eyePopOver}>
                        <HexColorPicker color={tempEyeColor} onChange={setTempEyeColor} />
                      </Box>
                    )
                  }
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColorSecondary}
                    display="flex"
                  >
                    QR Code - Eye Color (Default is brand color)
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      bg={isOpenEyeBallColorPicker ? tempEyeBallColor : eyeBallColor}
                      borderRadius='8px'
                      cursor={user?.firmRole !== 'admin' ? 'not-allowed' : 'pointer'}
                      onClick={user?.firmRole !== 'admin' ? () => {} : () => setIsOpenEyeBallColorPicker(prev => !prev)}
                    />
                    <Input pl={12} value={isOpenEyeBallColorPicker ? tempEyeBallColor : eyeBallColor}
                           onChange={onChangeHexColor} maxLength={7} disabled={user?.firmRole !== 'admin'} />
                  </InputGroup>
                  {
                    isOpenEyeBallColorPicker && (
                      <Box mt={4} ref={eyeBallPopOver}>
                        <HexColorPicker color={tempEyeBallColor} onChange={setTempEyeBallColor} />
                      </Box>
                    )
                  }
                </FormControl>
              </GridItem>
            </Grid>
            <Text color={textColorSecondary} fontSize='sm' mt={2}>
              * Use these colors to uniquely customize your QR code to enhance visibility and distinctiveness.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='outline' colorScheme='brand' mr={3} onClick={() => {
              onClose()
              setIsOpenEyeColorPicker(false);
              setIsOpenEyeBallColorPicker(false);
            }}>
              Cancel
            </Button>
            <Button variant='solid' colorScheme='brand' onClick={handleConfirmClick} isLoading={isGenerating}
                    disabled={!!errorSlugMessage || !tempSlug}>OK</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Generate Snippet Modal */}
      <SnippetModal link={`${REACT_APP_SMARTLEAD_BASE_URL}/s/${slug}`} firmName={user?.fullName || 'smartleads'}
                    isOpen={isSnippetOpen} onClose={onSnippetClose} />
    </Box>
  );
};

export default SmartLeads;
