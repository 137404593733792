import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable, } from 'react-table';
import { convertToNumber, numberFormat } from '../../../../libraries/utils';
import { MdEdit, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import SmartPortfolioModal from "../../modals/components/SmartPortfolioModal";
import Select from "react-select";
import { finicityAccountType } from "../../../../config/constant";
import { removeAccount, updateAccountInformation } from "../../../../api/institution-service";
import { useAuth } from "../../../../auth-context/auth.context";

const tableColumnsTopCreators = [{
  Header: 'Name', accessor: 'institutionName',
}, {
  Header: 'Portfolio', accessor: 'portfolio',
}, {
  Header: 'Balance', accessor: 'balance',
},];


function AccountBalance(props) {
  const {selectedClient} = useAuth();
  const {balanceData, balanceLoading, setSelectedSecurityId, onMarketDataOpen} = props;
  const [accountData, setAccountData] = useState(balanceData);
  const toast = useToast();
  const columns = useMemo(() => tableColumnsTopCreators, [tableColumnsTopCreators]);
  const data = useMemo(() => accountData, [accountData]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isShowBalance, setIsShowBalance] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  const {isOpen: isAccountOpen, onOpen: onAccountOpen, onClose: onAccountClose} = useDisclosure();
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure();
  const [institutionName, setInstitutionName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [balance, setBalance] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setAccountData(balanceData);
  }, [balanceData]);

  const tableInstance = useTable({
    columns, data,
  }, useGlobalFilter, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    prepareRow
  } = tableInstance;

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');

  const handleEdit = async () => {
    try {
      setIsUpdating(true);
      if (!selectedAccount || !institutionName || !accountName) {
        toast({
          title: "Update Account",
          description: "Check all information are valid.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      await updateAccountInformation(selectedAccount.accountId, institutionName, accountName, accountType?.value, convertToNumber(balance));
      toast({
        title: "Update Account",
        description: "Successfully updated the account details.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      const updatedData = accountData.map(account => account.accountId === selectedAccount.accountId ? {
        ...account,
        institutionName,
        accountName,
        type: accountType?.value,
        balance
      } : account);
      setAccountData(updatedData);
      onAccountClose();
    } catch (e) {
      toast({
        title: "Update Account",
        description: "There was an error. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      // Perform your delete operation here
      console.log(`Deleting account: ${selectedAccount.accountId}`);
      await removeAccount(selectedAccount?.accountId, selectedClient.userId);
      setAccountData(accountData.filter(account => account.accountId !== selectedAccount.accountId));
      onDeleteClose();
      onAccountClose();
      toast({
        title: "Delete Account",
        description: "Account successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: "Delete Account",
        description: "There was an error. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleClose = () => {
    setInstitutionName('');
    setAccountName('');
    setAccountType('');
    setBalance('');
    onAccountClose();
  }

  const accountTypeOptions = Object.entries(finicityAccountType).map(([key, value]) => ({
    value: key, label: value.displayName,
  }));

  const handleChangeAccount = (accountId) => {
    setSelectedAccount(accountData.find(account => account.accountId === accountId));
  }

  return (<>
    <Flex
      direction="column"
      w="100%"
      overflowX="auto">
      <Flex
        direction={{base: 'column', md: 'row'}}
        align={{sm: 'flex-start', md: 'center'}}
        justify="space-between"
        w="100%"
        px="22px"
        pb="20px"
        mb="10px"
        boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)">
        <Text color={textColor} fontSize="xl" fontWeight="600">
          Balances
        </Text>
        <Flex
          width="100%"
          align="center"
          justify="flex-end"
          gap={2}
        >
          <Button
            minW="97px"
            display={{
              base: 'block', lg: 'none'
            }}
            variant="action"
            onClick={() => setIsExpanded(prev => !prev)}
          >{isExpanded ? 'Collapse' : 'Expand'}</Button>
          <Button
            variant="no-hover"
            bg="transparent"
            p="0px"
            minW="unset"
            minH="unset"
            h="18px"
            w="max-content"
            disabled={!canPreviousPage}
            onClick={previousPage}
          >
            <Icon cursor="pointer" as={MdKeyboardArrowLeft} color={brandColor} w="24px"
                  h="24px"/>
          </Button>
          <Button
            variant="no-hover"
            bg="transparent"
            p="0px"
            minW="unset"
            minH="unset"
            h="18px"
            w="max-content"
            disabled={!canNextPage}
            onClick={nextPage}
          >
            <Icon cursor="pointer" as={MdKeyboardArrowRight} color={brandColor} w="24px"
                  h="24px"/>
          </Button>
          <Button
            minW="140px"
            onClick={() => setIsShowBalance(prev => !prev)}
          >{isShowBalance ? 'Hide' : 'Show'} Balance</Button>
        </Flex>
      </Flex>
      {isExpanded && <Table {...getTableProps()} variant="simple" color="gray.500">
        <Thead>
          {headerGroups.map((headerGroup, index) => (<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (<Th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              pe="10px"
              key={index}
              borderColor="transparent">
              <Flex
                justify="space-between"
                align="center"
                fontSize={{sm: '10px', lg: '12px'}}
                color="gray.400">
                {column.render('Header')}
              </Flex>
            </Th>))}
          </Tr>))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {balanceLoading ? <>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (<Tr key={item}>
              <Td colSpan={6} p={2}>
                <Skeleton h="20px"/>
              </Td>
            </Tr>))}
          </> : page.map((row, index) => {
            prepareRow(row);
            return (<Tr {...row.getRowProps()} key={index}>
              {row.cells.map((cell, index) => {
                let data = '';
                if (cell.column.Header === 'Name') {
                  data = (<Flex
                    align="center"
                    justify="space-between"
                    position="relative"
                    _hover={{'.editButton': {visibility: 'visible'}}}  // Target the button visibility on hover
                  >
                    <Flex align="center">
                      <Avatar src={cell.row.original.icon} w="30px" h="30px"
                              me="8px"/>
                      <Text color={textColor} fontSize="md" fontWeight="600">
                        {cell.row.original.institutionName} # {cell.row.original.accountNumber}
                      </Text>
                    </Flex>
                    <Button
                      variant="ghost"
                      size="sm"
                      colorScheme="brand"
                      className="editButton"
                      visibility="hidden"  // Initially hidden
                      onClick={() => {
                        setSelectedAccount(cell.row.original);
                        setInstitutionName(cell.value);
                        setAccountName(cell.row.original.accountName);
                        setAccountType(accountTypeOptions.find(option => option.value === cell.row.original.type));
                        setBalance(parseFloat(cell.row.original.balance));
                        onAccountOpen();
                      }}
                    >
                      <Icon as={MdEdit} boxSize={4}/>
                    </Button>
                  </Flex>);
                } else if (cell.column.Header === 'Portfolio') {
                  data = (<Button
                    colorScheme='brand'
                    fontSize="sm"
                    size='sm'
                    onClick={() => {
                      setSelectedAccount(cell.row.original)
                      onOpen();
                    }}>
                    smartportfolio
                  </Button>);
                } else if (cell.column.Header === 'Balance') {
                  data = (<Text
                    color={textColorSecondary}
                    fontSize="md"
                    fontWeight="500">
                    {isShowBalance ? `$${numberFormat(cell.value)}` : <Skeleton h="20px"/>}
                  </Text>);
                }
                return (<Td
                  {...cell.getCellProps()}
                  key={index}
                  fontSize={{sm: '14px'}}
                  py="8px"
                  minW={{sm: '150px', md: '200px', lg: 'auto'}}
                  borderColor="transparent">
                  {data}
                </Td>);
              })}
            </Tr>);
          })}
        </Tbody>
      </Table>}
      <SmartPortfolioModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setSelectedAccount(undefined);
        }}
        accounts={accountData}
        onChangeAccount={handleChangeAccount}
        account={selectedAccount}
        setSelectedSecurityId={setSelectedSecurityId}
        onMarketDataOpen={onMarketDataOpen}
      />
    </Flex>
    <Modal isOpen={isAccountOpen} onClose={handleClose} isCentered size='xl'>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Edit Account Info</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>

          <FormControl mb={3}>
            <FormLabel>Institution Name</FormLabel>
            <Input placeholder="Name" value={institutionName}
                   onChange={(e) => setInstitutionName(e.target.value)}/>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Account Name</FormLabel>
            <Input placeholder="Name" value={accountName}
                   onChange={(e) => setAccountName(e.target.value)}/>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Account Type</FormLabel>
            <Select
              value={accountType}
              placeholder="Select an account type"
              onChange={setAccountType}
              options={accountTypeOptions}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Balance</FormLabel>
            <Input placeholder="0" value={balance} type='number'
                   onChange={(e) => setBalance(e.target.value)}/>
          </FormControl>

        </ModalBody>
        <ModalFooter>
          <HStack justify='space-between' w='full'>
            <Button colorScheme="red" onClick={onDeleteOpen}
                    visibility={selectedAccount?.accountId?.includes('manual_') ? 'visible' : 'hidden'}>
              Delete
            </Button>
            <HStack>
              <Button colorScheme="brand" mr={3} onClick={handleEdit} isLoading={isUpdating}>
                Save
              </Button>
              <Button variant="ghost" colorScheme="brand" onClick={handleClose}>Cancel</Button>
            </HStack>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>

    {/* Confirmation Modal for Delete */}
    <Modal isOpen={isDeleteOpen} onClose={onDeleteClose} isCentered>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Text>Are you sure you want to
            delete <b>{`${selectedAccount?.institutionName}#${selectedAccount?.accountNumber}`}</b>? You
            will not be
            able to recover it once it's removed.</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={handleDelete} mr={3} isLoading={isDeleting}>Delete</Button>
          <Button variant="ghost" onClick={onDeleteClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>);
}

export default AccountBalance;
