import React, { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
import { CirclesWithBar } from 'react-loader-spinner';
import ReactApexChart from 'react-apexcharts';
import {
  createOrUpdateSecurityByAdvisor,
  deleteSecurityByAdvisor,
  downloadSecuritiesByAdvisor,
  getSmartPortfolioInfo
} from "../../../../api/fasttrack-service";
import { useAuth } from "../../../../auth-context/auth.context";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { countDecimals, currencyFormat, getAccountTypeInfo } from "../../../../libraries/utils";
import { AddIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import AddSecurityModal from "./AddSecurityModal";
import { MdEdit } from "react-icons/md";
import Select from "react-select";

interface SmartPortfolioProps {
  isOpen: boolean,
  onClose: () => void,
  onChangeAccount: (accountId: string) => void,
  account: any,
  accounts: any[],
  setSelectedSecurityId: (id: string) => void,
  onMarketDataOpen: () => void,
}

const DEFAULT_CHARTDATA = {
  options: {
    labels: [],
    responsive: [{
      breakpoint: 380,
      options: {
        chart: {
          width: 300
        },
        legend: {
          position: 'bottom'
        }
      }
    },
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 400
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ],
  },
  series: [],
}

const DEFAULT_LINECHART = {
  options: {
    colors: ["#dc1449", "#2fb520", "#3455da"],
    chart: {
      id: 'smartcast-line-chart',
      type: 'line',
      toolbar: {
        show: true,
      },
    },
    xaxis: {
      categories: [],
      title: {
        text: 'Year',
      },
    },
    yaxis: {
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true,
      },
      labels: {
        style: {}
      },
      title: {
        text: 'Return (%)',
        style: {}
      },
    },
  },
  series: [],
}

const tableColumns: any = [
  {
    Header: 'Symbol',
    accessor: 'tickerSymbol',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Market Value',
    accessor: 'marketValue',
  },
  {
    Header: 'Asset Class',
    accessor: 'category',
  },
  {
    Header: 'Exp Ratio',
    accessor: 'expenseRatio',
    Cell: ({ value }: any) => {
      const maxDigits = countDecimals(value);
      return value === null ? 'N/A' : `${(value * 100).toLocaleString(undefined, { maximumFractionDigits: maxDigits })}%`
    },
  },
  {
    Header: '12B-1 FEE',
    accessor: 'b12',
    Cell: ({ value }: any) => {
      const maxDigits = countDecimals(value);
      return value === null ? 'N/A' : `${(value * 100).toLocaleString(undefined, { maximumFractionDigits: maxDigits })}%`
    },
  },
  {
    Header: 'Front Load',
    accessor: 'frontLoad',
    Cell: ({ value }: any) => {
      const maxDigits = countDecimals(value);
      return value === null ? 'N/A' : `${(value * 100).toLocaleString(undefined, { maximumFractionDigits: maxDigits })}%`
    },
  },
  {
    Header: 'Fund Fee',
    accessor: 'smartFee',
    Cell: ({ value }: any) => `$${currencyFormat(value)}`,
  },
  {
    Header: 'Other',
    accessor: 'other',
  },
];

const SmartPortfolioModal: React.FC<SmartPortfolioProps> = ({
                                                              isOpen,
                                                              onClose,
                                                              account,
                                                              onChangeAccount,
                                                              accounts,
                                                              setSelectedSecurityId,
                                                              onMarketDataOpen
                                                            }) => {
  // Chakra Color Mode
  const textColor = useColorModeValue("navy.700", "white");

  const { selectedClient, user } = useAuth();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [chartData, setChartData] = useState<any>(DEFAULT_CHARTDATA)
  const [portfolioData, setPortfolioData] = useState<any>(undefined);
  const [feeOfOneYear, setFeeOfOneYear] = useState(0);
  const [totalSmartFees, setTotalSmartFees] = useState(0);
  const [smartTestData, setSmartTestData] = useState<any>(undefined);

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [selectedSecurity, setSelectedSecurity] = useState<any>(null);
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const [isSecurityAdding, setIsSecurityAdding] = useState(false);
  const [isExportingRisk, setIsExportingRisk] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [accountList, setAccountList] = useState<any>([]);

  useEffect(() => {
    if (account) {
      const accountName = `${account.institutionName} # ${account.accountNumber}`;
      setSelectedAccount({
        value: account?.accountId,
        label: accountName,
      });
    }
  }, [account]);

  useEffect(() => {
    const accFilterList = accounts.map(account => {
      const accountName = `${account.institutionName} # ${account.accountNumber}`;
      return ({
        value: account.accountId,
        label: accountName,
      });
    });
    setAccountList(accFilterList);
  }, [accounts]);

  const handleChangeAccount = (data: { value: string, label: string }) => {
    setSelectedAccount(data);
    onChangeAccount(data?.value);
  }

  const [lineChartData, setLineChartData] = useState<any>(DEFAULT_LINECHART);

  useEffect(() => {
    if (selectedClient && account) {
      getSmartPortfolioData();
    }
  }, [selectedClient, account]);

  const getSmartPortfolioData = async () => {
    try {
      setIsLoading(true);
      const {
        smartPortfolioData,
        smartTestData,
        xspTestData,
        feeOfOneYear,
        totalSmartFees
      } = await getSmartPortfolioInfo(selectedClient.userId, account.accountId);
      setSmartTestData(smartTestData);
      setChartData((prev: any) => ({
        ...prev,
        options: {
          ...prev.options,
          labels: smartPortfolioData.map((item: any) => `${item.tickerSymbol}`),
          legend: {
            ...prev.options.legend,
            show: true,
            position: 'bottom',
            floating: false,
            horizontalAlign: 'left',
            formatter: (seriesName: string, opts: any) => {
              // This will show the ticker symbol next to the percentage in the legend.
              return `${seriesName} - ${smartPortfolioData[opts.seriesIndex].name}`;
            },
          },
        },
        series: smartPortfolioData.map((item: any) => item.marketValue || 1),
      }));
      setPortfolioData(smartPortfolioData);
      setFeeOfOneYear(feeOfOneYear);
      setTotalSmartFees(totalSmartFees);

      // Extract return data for the last 5 years
      const currentYear = new Date().getFullYear();
      const recentFiveYears = smartTestData.stats.returns.years
        .filter((data: any) => +data.year >= currentYear - 6) // Filter the last 5 years
        .map((data: any) => ({
          year: data.year,
          return: parseFloat(data.returns),
          returnWithoutFee: parseFloat(data.returns_without_fee),
          value: data.value,
          valueWithoutFee: data.value_without_fee,
          gainLoss: data.gain_loss,
          gainLossWithoutFee: data.gain_loss_without_fee,
        }));

      const xspFiveYears = xspTestData.stats.returns.years
        .filter((data: any) => +data.year >= currentYear - 6) // Filter the last 5 years
        .map((data: any) => ({ year: data.year, return: parseFloat(data.returns) }));

      const smartTestSeries = [
        {
          name: "Portfolio Return (%)",
          type: 'line',
          data: recentFiveYears.map((data: any) => data.return),
        },
        {
          name: "Portfolio Return without Fee (%)",
          type: 'line',
          data: recentFiveYears.map((data: any) => data.returnWithoutFee),
        },
        {
          name: "Benchmark Return (%)",
          type: 'line',
          data: xspFiveYears.map((data: any) => data.return),
        },
      ];

      setLineChartData({
        options: {
          ...lineChartData.options,
          xaxis: {
            categories: recentFiveYears.map((data: any) => data.year),
          },
        },
        series: smartTestSeries
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  const handleClose = () => {
    setChartData(DEFAULT_CHARTDATA);
    onClose();
  }

  const onRemoveHolding = async (securityId: string, symbolName: string) => {
    setIsDeleting(true);
    await deleteSecurityByAdvisor(selectedClient?.userId, securityId)
      .then(() => {
        toast({
          title: 'Delete security',
          description: `${symbolName} has been successfully removed.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setPortfolioData((prevData: any) => prevData.filter((item: any) => item.securityId !== securityId));
        onDeleteClose();
      })
      .catch(error => {
        console.error(error.message);
        toast({
          title: 'Delete security',
          description: error.response?.data.message ?? error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsDeleting(false);
      })
  }

  const onSecuritySave = ({ symbol, name, marketValue, securityId }: {
    symbol: string,
    name: string,
    marketValue: number,
    securityId?: string
  }) => {
    const securityName = name.split('--')[1].trim();
    setIsSecurityAdding(true);
    createOrUpdateSecurityByAdvisor(selectedClient?.userId, account.accountId, symbol, securityName, marketValue, securityId)
      .then(({ security }) => {
        toast({
          title: `${securityId ? 'Edit' : 'Add'} ticker`,
          description: `${name} has been successfully ${securityId ? 'updated' : 'added'}.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // setPortfolioData((prevData: any) => {
        //   // Update the security if it exists, otherwise add the new security
        //   const existingIndex = prevData.findIndex((item: any) => item.securityId === security.securityId);
        //   let newData;
        //
        //   if (existingIndex !== -1) {
        //     // If the security already exists in the data, update it
        //     newData = [...prevData];
        //     newData[existingIndex] = security;
        //   } else {
        //     // Otherwise, it's a new addition
        //     newData = [...prevData, security];
        //   }
        //   // Sort the new array by tickerSymbol
        //   return newData.sort((a, b) => a.tickerSymbol.localeCompare(b.tickerSymbol));
        // });
        setSelectedSecurity(security);
        getSmartPortfolioData();
        onAddClose();
      })
      .catch((error) => {
        toast({
          title: `${securityId ? 'Edit' : 'Add'} ticker`,
          description: error.response?.data.message ?? error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setIsSecurityAdding(false))
  }

  const onExportRisk = () => {
    setIsExportingRisk(true);
    downloadSecuritiesByAdvisor(selectedClient?.userId, account.accountId)
      .then(({ data }) => {
        try {
          // Assuming `security` is a base64 encoded CSV string returned from the backend
          const binaryString = window.atob(data); // Decoding the base64 string
          const len = binaryString.length;
          const bytes = new Uint8Array(len);

          // Convert binary string to bytes
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Create a downloadable CSV blob
          const urlBlob = window.URL.createObjectURL(new Blob([bytes.buffer], { type: 'text/csv' }));
          const link = document.createElement('a');
          link.href = urlBlob;
          link.setAttribute('download', `${selectedClient?.lastName?.toLowerCase()}portfolio - ${account?.name} ${account?.title}'.csv`);
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        } catch (err) {
          throw new Error('Failed to download the file');
        }
      })
      .catch((error) => {
        toast({
          title: 'Export',
          description: error.response?.data.message ?? error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setIsExportingRisk(false))
  }

  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data: any = useMemo(() => {
    if (!portfolioData) return [];
    return portfolioData
  }, [portfolioData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='full'>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader mt={6}>
          <HStack justify='space-between'>
            <HStack color={textColor}>
              <Avatar
                src={account?.icon}
                title={account?.institutionName}
                w="30px"
                h="30px"
                me="8px"
              />
              <Text
                fontSize='2xl'>{`${account?.institutionName} # ${account?.accountNumber}`} smartportfolio™
              </Text>
            </HStack>
            <Image cursor="pointer" src={user?.firm?.logoUrl} h='32px'
                   display={{ base: 'none', md: 'block' }}/>
          </HStack>
          <FormControl mt={3} maxW='sm'>
            <FormLabel>Switch to another account</FormLabel>
            <Select
              value={selectedAccount}
              placeholder="Select an account"
              onChange={handleChangeAccount}
              options={accountList}
            />
          </FormControl>
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          {
            isLoading ? <Center h="100%" minH='400px' justifyContent="center" py="32px">
                <CirclesWithBar
                  height="120"
                  width="120"
                  color="#70ad45"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  outerCircleColor=""
                  innerCircleColor=""
                  barColor=""
                  ariaLabel="circles-with-bar-loading"
                />
              </Center> :
              <Box>
                <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight='800'
                      color='brand.500'>{account?.accountName} {getAccountTypeInfo(account?.type)}</Text>
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={1} mb={4}
                      alignItems='start'>
                  <HStack color={textColor}>
                    <Text fontSize='xl' fontWeight='700'>Total Balance:</Text>
                    <Text fontSize={{ base: '2xl', md: '3xl' }}
                          fontWeight='800'>$ {account?.balance ? currencyFormat(account?.balance) : 0}</Text>
                  </HStack>
                  <Box>
                    <Flex direction={{ base: 'column', md: 'row' }} color={textColor}
                          align={{ base: 'start', md: 'center' }} gap={{ base: 0, md: 2 }}>
                      <Text fontSize='xl' fontWeight='700' whiteSpace="nowrap">
                        Total smartfees™:
                      </Text>
                      <Text fontSize={{ base: '2xl', md: '3xl' }} fontWeight='800'
                            color='red.400'>
                        $ {currencyFormat(totalSmartFees + feeOfOneYear)} {
                        account?.balance && Number(account.balance) !== 0 ? ` (${currencyFormat(((totalSmartFees + feeOfOneYear) / account?.balance) * 100)}%)` : ''}
                      </Text>
                    </Flex>
                    <Flex gap={4}>
                      <HStack>
                        <Tooltip label='expense ratio, 12b-1, frontLoad' hasArrow
                                 placement='auto'>
                          <Text fontSize='sm' color={textColor} fontWeight='500'
                                cursor='help'>
                            Fund fees:
                          </Text>
                        </Tooltip>
                        <Text fontSize='sm' color={textColor} fontWeight='700'>
                          $ {currencyFormat(totalSmartFees)}
                        </Text>
                      </HStack>
                      <HStack>
                        <Tooltip label='Transaction fees over a 12-month period.' hasArrow
                                 placement='auto'>
                          <Text fontSize='sm' color={textColor} fontWeight='500'
                                cursor='help'>
                            Advisor fees:
                          </Text>
                        </Tooltip>
                        <Text fontSize='sm' color={textColor} fontWeight='700'>
                          $ {currencyFormat(feeOfOneYear)}
                        </Text>
                      </HStack>
                    </Flex>
                  </Box>
                </Grid>
                <VStack>
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="pie"
                    width={600}/>
                </VStack>
                <HStack mt={4} w='full' justify='end'>
                  <Button
                    colorScheme='brand'
                    leftIcon={<DownloadIcon/>}
                    onClick={onExportRisk}
                    isLoading={isExportingRisk}
                  >Export</Button>
                  <Button
                    colorScheme='brand'
                    leftIcon={<AddIcon/>}
                    onClick={() => {
                      setSelectedSecurity(null);
                      onAddOpen();
                    }}
                  >Add Ticker</Button>
                </HStack>
                <Box overflowX="auto" overflowY="auto" maxH="400px" mt={2}>
                  <Table {...getTableProps()} variant="striped" color="gray.500">
                    <Thead>
                      {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                          {headerGroup.headers.map((column, index) => (
                            <Th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              key={index}
                              borderColor="transparent">
                              <Flex
                                justify="space-between"
                                align="center"
                                color="gray.400">
                                {column.render('Header')}
                              </Flex>
                            </Th>
                          ))}
                        </Tr>
                      ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                      {isLoading ? <>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                          <Tr key={item}>
                            <Td colSpan={6} p={2}>
                              <Skeleton h="20px"/>
                            </Td>
                          </Tr>
                        ))}
                      </> : rows.map((row, index) => {
                        prepareRow(row);
                        return (
                          <Tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell: any, index) => {
                              if (cell.column.Header === 'Symbol') {
                                return (
                                  <Td
                                    {...cell.getCellProps()}
                                    key={index}
                                    fontSize='md'
                                    py="8px"
                                    borderColor="transparent">
                                    {
                                      cell.value !== cell.row.original.name ?
                                        <Button size='sm' variant='link'
                                                onClick={() => {
                                                  setSelectedSecurityId(cell.row.original.securityId);
                                                  onMarketDataOpen();
                                                }}>
                                          <Tooltip
                                            label={cell.row.original.name}
                                            hasArrow placement="top">
                                            <Text
                                              color={textColor}
                                              fontSize="lg"
                                              fontWeight="500">
                                              {cell.value}
                                            </Text>
                                          </Tooltip>
                                        </Button> :
                                        <IconButton
                                          title={'Add ticker symbol'}
                                          aria-label='edit'
                                          bg='inherit'
                                          borderRadius='16px'
                                          _active={{
                                            bg: "inherit",
                                            transform: "none",
                                            borderColor: "transparent",
                                          }}
                                          _focus={{
                                            boxShadow: "none",
                                          }}
                                          icon={
                                            <AddIcon color={'brand.500'}/>
                                          }
                                          onClick={() => {
                                            setSelectedSecurity(cell.row.original);
                                            onAddOpen();
                                          }}
                                        />
                                    }
                                  </Td>
                                )
                              }
                              if (cell.column.Header === 'Asset Class') {
                                let assetClass = cell.value;
                                if (!assetClass) {
                                  assetClass = cell.row.original.securityType === 'Stock' ? 'Equities' : cell.row.original.securityType;
                                }

                                return (
                                  <Td
                                    {...cell.getCellProps()}
                                    key={index}
                                    fontSize='md'
                                    py="8px"
                                    borderColor="transparent">
                                    {assetClass}
                                  </Td>
                                );
                              }
                              if (cell.column.Header === 'Market Value') {
                                return (
                                  <HStack
                                    align="center"
                                    position="relative"
                                    _hover={{ '.editButton': { visibility: 'visible' } }}  // Target the button visibility on hover
                                  >
                                    <Td
                                      {...cell.getCellProps()}
                                      key={index}
                                      fontSize='md'
                                      borderColor="transparent">
                                      ${currencyFormat(cell.value)}
                                    </Td>
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      colorScheme="brand"
                                      className="editButton"
                                      visibility="hidden"  // Initially hidden
                                      onClick={() => {
                                        setSelectedSecurity(cell.row.original);
                                        onAddOpen();
                                      }}
                                    >
                                      <Icon as={MdEdit} boxSize={4}/>
                                    </Button>
                                  </HStack>
                                );
                              }
                              if (cell.column.Header === 'Other') {
                                return (
                                  <Td
                                    {...cell.getCellProps()}
                                    key={index}
                                    fontSize='md'
                                    py="8px"
                                    borderColor="transparent">
                                    <IconButton
                                      aria-label='delete'
                                      title={'Delete'}
                                      bg='inherit'
                                      borderRadius='16px'
                                      _active={{
                                        bg: "inherit",
                                        transform: "none",
                                        borderColor: "transparent",
                                      }}
                                      _focus={{
                                        boxShadow: "none",
                                      }}
                                      icon={
                                        <DeleteIcon color={'red.500'}/>
                                      }
                                      onClick={() => {
                                        setSelectedSecurity(cell.row.original);
                                        onDeleteOpen();
                                      }}
                                    />
                                  </Td>
                                );
                              }
                              return (
                                <Td
                                  {...cell.getCellProps()}
                                  key={index}
                                  fontSize='md'
                                  py="8px"
                                  borderColor="transparent">
                                  {cell.render('Cell')}
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })}
                    </Tbody>
                    <Tfoot
                      style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: '#E1E9F8',
                      }}>
                      <Tr>
                        {headerGroups[0].headers.map((column, index) => (
                          <Td
                            key={index}
                            borderColor="transparent"
                            fontSize="md"
                            py="8px"
                            fontWeight="bold"
                          >
                            {index === columns.length - 2 ? (
                              'Total Fund fees' // Label for the total in the first cell
                            ) : index === columns.length - 1 ? (
                              `$${totalSmartFees.toFixed(2)}`
                            ) : (
                              '' // Empty cells for the rest
                            )}
                          </Td>
                        ))}
                      </Tr>
                    </Tfoot>
                  </Table>
                </Box>
                <Divider mt={4}/>
                <Box>
                  <Text color={textColor} fontSize='xl' fontWeight='700' mt={2}>
                    smarttest™
                  </Text>
                  <Box w='full' textAlign='center' bg='gray.100' p={4} borderRadius='md'>
                    <Text fontSize='xl' fontWeight='bold'>
                      Portfolio vs Benchmark
                    </Text>
                  </Box>
                  <Box w={{ base: '100%', md: '60%' }} mx='auto'>
                    <ReactApexChart
                      options={lineChartData.options}
                      series={lineChartData.series}
                      type="line" height={500}
                    />
                  </Box>

                  {
                    smartTestData &&
                    <Box>
                      <Box>
                        {/* New Section for Showing Detailed Returns Table */}
                        <Text color={textColor} fontSize='xl' fontWeight='700' mt={8}>
                          Detailed Yearly Returns
                        </Text>
                        <Box bg='gray.50' borderRadius='md' p={4} my={4} overflowX='auto'>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th>Year</Th>
                                <Th>Returns (%)</Th>
                                <Th>Value ($)</Th>
                                <Th>Gain/Loss ($)</Th>
                                <Th>Returns Without Fee (%)</Th>
                                <Th>Value Without Fee ($)</Th>
                                <Th>Gain/Loss Without Fee ($)</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {smartTestData.stats.returns.years.map((yearData: any, index: number) => (
                                <Tr key={index}>
                                  <Td>{yearData.year}</Td>
                                  <Td>{yearData.returns}</Td>
                                  <Td>${parseFloat(yearData.value).toLocaleString()}</Td>
                                  <Td>${parseFloat(yearData.gain_loss).toLocaleString()}</Td>
                                  <Td>{yearData.returns_without_fee}</Td>
                                  <Td>${parseFloat(yearData.value_without_fee).toLocaleString()}</Td>
                                  <Td>${parseFloat(yearData.gain_loss_without_fee).toLocaleString()}</Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </Box>
                      </Box>

                      {/* New Section for Showing Detailed Statistics */}
                      <Text color={textColor} fontSize='xl' fontWeight='700' mt={8}>
                        Portfolio Details
                      </Text>
                      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} bg='gray.50'
                            borderRadius='md' p={4} my={4}>
                        <VStack align='start' spacing={3}>
                          <Text fontSize='lg' fontWeight='600'>Annualized (CAGR) / Total returns</Text>
                          <Text>One Day Return: {smartTestData.stats.returns.annualized.oneday}</Text>
                          <Text>Five Day Return: {smartTestData.stats.returns.annualized.fiveday}</Text>
                          <Text>One Month Return: {smartTestData.stats.returns.annualized.onemonth}</Text>
                          <Text>Three Months Return: {smartTestData.stats.returns.annualized.threemonths}</Text>
                          <Text>Six Months Return: {smartTestData.stats.returns.annualized.sixmonths}</Text>
                          <Text>Nine Months Return: {smartTestData.stats.returns.annualized.ninemonths}</Text>
                          <Text>One Year Return: {smartTestData.stats.returns.annualized.one}</Text>
                          <Text>Three Year Return: {smartTestData.stats.returns.annualized.three}</Text>
                          <Text>Five Year Return: {smartTestData.stats.returns.annualized.five}</Text>
                          <Text>Ten Year Return: {smartTestData.stats.returns.annualized.ten}</Text>
                          <Text>Year-to-Date Return: {smartTestData.stats.returns.annualized.ytd}</Text>
                          <Text>Since Inception Return: {smartTestData.stats.returns.annualized.inception}</Text>
                        </VStack>

                        <VStack align='start' spacing={3}>
                          <Text fontSize='lg' fontWeight='600'>Risk Metrics</Text>
                          <Text>Alpha: {smartTestData.stats.risk.alpha}</Text>
                          <Text>Beta: {smartTestData.stats.risk.beta}</Text>
                          <Text>Correlation: {smartTestData.stats.risk.corr}</Text>
                          <Text>FT Alpha: {smartTestData.stats.risk.ftalpha}</Text>
                          <Text>Standard Deviation: {smartTestData.stats.risk.sd}</Text>
                          <Text>Sharpe Ratio: {smartTestData.stats.risk.sharpe}</Text>
                          <Text>Ulcer Index: {smartTestData.stats.risk.ui}</Text>
                          <Text>Ulcer Performance Index: {smartTestData.stats.risk.upi}</Text>
                          <Text>Relative Strength Index (RSI): {smartTestData.stats.risk.rsi}</Text>
                          <Text>R-Squared: {smartTestData.stats.risk.r_squared}</Text>
                          <Text>Treynor Ratio: {smartTestData.stats.risk.treynor}</Text>
                        </VStack>
                      </Grid>
                    </Box>
                  }

                  {/*<Flex*/}
                  {/*  overflowX='auto'*/}
                  {/*  width="full"*/}
                  {/*  py={2}*/}
                  {/*  gap={2}*/}
                  {/*  justify='space-between'*/}
                  {/*  align='end'*/}
                  {/*>*/}
                  {/*  <Text color={textColor} fontSize='lg' fontWeight='500' p={2}*/}
                  {/*        me={{base: 8, md: 16}}>*/}
                  {/*    Total Fee*/}
                  {/*  </Text>*/}
                  {/*  {*/}
                  {/*    SMARTCAST_YEARS.map((year: number, index: number) => (*/}
                  {/*      <VStack key={index} flexShrink={0} p={2}>*/}
                  {/*        <Text fontWeight={600}>{year}Y</Text>*/}
                  {/*        <Text>${currencyFormat((totalSmartFees + feeOfOneYear) * year)}</Text>*/}
                  {/*      </VStack>*/}
                  {/*    ))*/}
                  {/*  }*/}
                  {/*</Flex>*/}
                </Box>
              </Box>
          }
        </ModalBody>
        <ModalFooter justifyContent='start' w='full'>
          <Box w='full'>
            <Text color='gray.500' fontSize='sm'>
              Hypothetical performance shown is for illustrative purposes only and does not guarantee
              future results.
              Actual results will vary. Clarifees Inc. dba Smartdata does not guarantee the accuracy or
              completeness of
              information provided. There may be delays, omissions, or inaccuracies in the information.
            </Text>
            <HStack justify='end'>
              <Button colorScheme="brandScheme" variant="ghost"
                      onClick={() => handleClose()}>Close</Button>
            </HStack>
          </Box>
        </ModalFooter>
      </ModalContent>
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose} isCentered>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Delete {selectedSecurity?.tickerSymbol}</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Text fontSize='lg'>
              Are you sure to delete <b>{selectedSecurity?.name} ({selectedSecurity?.tickerSymbol})</b>?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='outline' colorScheme='brand' onClick={onDeleteClose} mr={3}>
              Close
            </Button>
            <Button variant='solid' colorScheme="red" isLoading={isDeleting}
                    onClick={() => {
                      onRemoveHolding(selectedSecurity?.securityId, selectedSecurity?.tickerSymbol);
                    }}
            >Yes</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AddSecurityModal isOpen={isAddOpen} onClose={onAddClose} onSave={onSecuritySave}
                        isSecurityAdding={isSecurityAdding} selectedSecurity={selectedSecurity}/>
    </Modal>
  );
}

export default SmartPortfolioModal;
