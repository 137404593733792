import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Textarea,
  Tooltip,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react';
import { AccountType, Management } from '../../../../config/constant';
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import moment from "moment";
import US_STATES from '../../../../config/us-sate.json';
import { isValidEmail, isValidUSPhone } from "../../../../libraries/utils";
import { useAuth } from "../../../../auth-context/auth.context";

interface UserAddModalProps {
  isOpen: boolean,
  onClose: () => void,
  onSave: (e: {
    firstName: string,
    middleName: string,
    lastName: string,
    nickName: string,
    birthday: string,
    email: string,
    phoneNumber: string,
    userRole: string,
    management: string,
    addressLine1: string,
    city: string,
    state: string,
    postalCode: string,
    companyName: string,
    note: string,
    category: 'Finance' | 'Tax',
    connectType?: 'CONNECT' | 'UPLOAD' | 'OFFICE',
    reportType?: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY',
  }) => Promise<void>
}

const AddClientModal: React.FC<UserAddModalProps> = ({ isOpen, onClose, onSave }) => {
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickName, setNickName] = useState('');
  const [birthday, setBirthday] = useState(new Date());
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userRole, setUserRole] = useState<AccountType>(AccountType.CLIENT);
  const [management, setManagement] = useState<Management>(Management.FINANCIAL);
  const [addressLine1, setAddressLine1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [note, setNote] = useState('');
  const [moreInfo, setMoreInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [category, setCategory] = useState<'Finance' | 'Tax'>('Finance');
  const [reportType, setReportType] = useState<'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY'>('TEN_DAY');
  const [connectType, setConnectType] = useState<'CONNECT' | 'UPLOAD' | 'OFFICE'>('CONNECT');

  const { user } = useAuth();

  // Get the current subscription from cogUser's subscriptions
  const currentFinanceSubscription = user?.subscriptions.find((sub: any) =>
    sub.plan.includes('SMARTFINANCE') && sub.status === 'active'
  );

  const currentTaxSubscription = user?.subscriptions.find((sub: any) =>
    sub.plan.includes('SMARTTAX') && sub.status === 'active'
  );

  const save = async () => {
    let message = null;

    if (!firstName) {
      message = 'First name is required.';
    } else if (!lastName) {
      message = 'Last name is required.';
    } else if (!email) {
      message = 'Email is required.';
    } else if (!isValidEmail(email)) {
      message = 'Invalid email address.';
    } else if (phoneNumber && !isValidUSPhone(phoneNumber)) {
      message = 'Please input valid phone number.';
    } else if (category === 'Finance' && currentFinanceSubscription?.status !== 'active') {
      message = 'Please confirm your smartfinance™ plan is active.';
    } else if (category === 'Tax' && currentTaxSubscription?.status !== 'active') {
      message = 'Please confirm your smarttax™ plan is active.';
    }

    if (message) {
      setErrorMessage(message);
      toast({
        title: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } else {
      setIsSaving(true);
      const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
      await onSave({
        addressLine1,
        birthday: moment(birthday).format('YYYY-MM-DD'),
        city,
        companyName,
        email: email.trim().toLowerCase(),
        firstName,
        lastName,
        management,
        middleName,
        nickName,
        phoneNumber: phoneNumberDigits ? `+1${phoneNumberDigits}` : '',
        postalCode,
        state,
        userRole,
        note,
        category,
        ...(category === 'Finance' ? { connectType } : {}),
        ...(category === 'Tax' ? { reportType } : {}),
      });
      setIsSaving(false);
      handleClose();
    }
  }

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let onlyNums = value.replace(/\D/g, '');
    let formattedNumber;
    if (onlyNums.length <= 3) {
      formattedNumber = onlyNums;
    } else if (onlyNums.length <= 6) {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    } else {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    }

    setPhoneNumber(formattedNumber);
    setErrorMessage(null);
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setErrorMessage(null);
  }

  const handleCompanyName = (value: string) => {
    setCompanyName(value);
    setErrorMessage(null);
  }

  const handleClose = () => {
    onClose();
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setNickName('');
    setBirthday(new Date());
    setEmail('');
    setPhoneNumber('');
    setCompanyName('');
    setAddressLine1('');
    setCity('');
    setState('');
    setPostalCode('');
    setUserRole(AccountType.CLIENT);
    setManagement(Management.FINANCIAL);
    setCategory('Finance');
    setErrorMessage(null);
  }

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Prospect</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid
            templateColumns={useBreakpointValue({ base: '1fr', md: 'repeat(3, 1fr)' })}
            gap={useBreakpointValue({ base: 4, md: 2 })}
          >
            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input placeholder='John' value={firstName}
                     onChange={(e) => {
                       setFirstName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl>
              <FormLabel>Middle Name</FormLabel>
              <Input placeholder='Fortune (Optional)' value={middleName}
                     onChange={(e) => {
                       setMiddleName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input placeholder='Doe' value={lastName}
                     onChange={(e) => {
                       setLastName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
          </Grid>
          <Grid
            mt="12px"
            templateColumns={useBreakpointValue({ base: '1fr', md: 'repeat(2, 1fr)' })}
            gap={useBreakpointValue({ base: 4, md: 2 })}
          >
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input placeholder='you@example.com' type="email" colorScheme="brandScheme" value={email}
                     onChange={(e) => handleEmailChange(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input colorScheme="brandScheme" value={phoneNumber}
                     type="tel"
                     placeholder="(201)-555-0123 (Optional)"
                     onChange={(e) => handlePhoneNumberChange(e)} />
            </FormControl>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Note</FormLabel>
                <Textarea placeholder='Internal note about prospect' value={note}
                          rows={3}
                          onChange={(e) => {
                            setNote(e.target.value);
                          }} />
              </FormControl>
            </GridItem>
          </Grid>
          <Button mt='12px' variant='link' size='sm' colorScheme='navy' onClick={() => setMoreInfo(prev => !prev)}>
            {moreInfo ? '-' : '+'} More Information
          </Button>
          {
            moreInfo &&
            <Grid
              mt="12px"
              templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
              gap={{ base: 4, md: 2 }}
            >
              <FormControl>
                <FormLabel>Nick Name</FormLabel>
                <Input placeholder='nickname (Optional)' value={nickName}
                       onChange={(e) => {
                         setNickName(e.target.value);
                         setErrorMessage(null);
                       }} />
              </FormControl>
              <FormControl>
                <FormLabel>Date of Birth</FormLabel>
                <SingleDatepicker
                  name="date-input"
                  maxDate={new Date()}
                  date={birthday}
                  onDateChange={setBirthday}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Company Name</FormLabel>
                <Input placeholder='Company (Optional)' colorScheme="brandScheme" value={companyName}
                       onChange={(e) => handleCompanyName(e.target.value)} marginBottom='8px' />
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input placeholder='Address Line1 (Optional)' colorScheme="brandScheme" value={addressLine1}
                       onChange={(e) => {
                         setAddressLine1(e.target.value);
                         setErrorMessage(null);
                       }} />
              </FormControl>
              <FormControl>
                <FormLabel>City</FormLabel>
                <Input placeholder='City (Optional)' colorScheme="brandScheme" value={city}
                       onChange={(e) => {
                         setCity(e.target.value);
                         setErrorMessage(null);
                       }} />
              </FormControl>
              <FormControl>
                <FormLabel>State/Province</FormLabel>
                <Select
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value);
                    setErrorMessage(null);
                  }}
                >
                  <option key={0} value=''>
                    Select a state...
                  </option>
                  {US_STATES.map((usState) => (
                    <option key={usState.abbreviation} value={usState.abbreviation}>
                      {usState.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Zip/Postal Code</FormLabel>
                <Input placeholder='Postal Code (Optional)' colorScheme="brandScheme" value={postalCode}
                       maxLength={6}
                       type="number"
                       onChange={(e) => {
                         setPostalCode(e.target.value);
                         setErrorMessage(null);
                       }} />
              </FormControl>
              <FormControl>
                <FormLabel>Country</FormLabel>
                <Input value='United States' colorScheme="brandScheme" readOnly />
              </FormControl>
            </Grid>
          }
          <FormControl mt={2}>
            <FormLabel>Prospect Category</FormLabel>
            <RadioGroup
              value={(currentFinanceSubscription?.status === 'active' || currentTaxSubscription?.status === 'active') ? category : ''}
              onChange={(e: 'Finance' | 'Tax') => setCategory(e)}>
              <HStack>
                <Radio colorScheme="brandScheme" isDisabled={currentFinanceSubscription?.status !== 'active'}
                       value='Finance'>
                  <Tooltip label='Subscribe to smartfinance™ plan.'
                           hidden={currentFinanceSubscription?.status === 'active'}>
                    SMARTFINANCE
                  </Tooltip>
                </Radio>
                <Radio colorScheme="brandScheme" isDisabled={currentTaxSubscription?.status !== 'active'}
                       value='Tax'>
                  <Tooltip label='Subscribe to smarttax™ plan.'
                           hidden={currentTaxSubscription?.status === 'active'}>
                    SMARTTAX
                  </Tooltip>
                </Radio>
              </HStack>
            </RadioGroup>
            {/*<Box hidden={category === 'Tax'}>*/}
            {/*  <FormLabel mt={2}>Invite Type</FormLabel>*/}
            {/*  <RadioGroup value={connectType} onChange={(e: 'CONNECT' | 'UPLOAD' | 'OFFICE') => setConnectType(e)}>*/}
            {/*    <HStack>*/}
            {/*      <Radio colorScheme="brandScheme" fontSize='sm'*/}
            {/*             value='CONNECT'>CONNECT</Radio>*/}
            {/*      <Radio colorScheme="brandScheme" fontSize='sm'*/}
            {/*             value='UPLOAD'>UPLOAD</Radio>*/}
            {/*    </HStack>*/}
            {/*  </RadioGroup>*/}
            {/*</Box>*/}
            <Box hidden={category === 'Finance'}>
              <FormLabel mt={2}>Report Type</FormLabel>
              <RadioGroup value={reportType} onChange={(e: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY') => setReportType(e)}>
                <HStack>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value='SAME_DAY'>SAME DAY</Radio>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value='NEXT_DAY'>NEXT DAY</Radio>
                  <Radio colorScheme="brandScheme" fontSize='sm'
                         value='TEN_DAY'>WITHIN 10 DAYS</Radio>
                </HStack>
              </RadioGroup>
            </Box>
            {(currentFinanceSubscription?.status !== 'active' && currentTaxSubscription?.status !== 'active') &&
              <FormHelperText color='red' fontSize='lg' fontWeight='bold' mt={2}>
                Subscribe to a plan from either smartfinance™ or smarttax™ to add a prospect.
              </FormHelperText>
            }
            {errorMessage ? (
              <FormHelperText color='red'>
                {errorMessage}
              </FormHelperText>
            ) : null}
          </FormControl>
          {/*<Text mt='8px'>*/}
          {/*  This user will be assigned*/}
          {/*  to <b>{getPlanName(user?.stripePlan)} plan</b> (<b>${getPlanPrice(user?.stripePlan)}/advisor/month</b>)*/}
          {/*</Text>*/}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brandScheme" mr={3} onClick={() => save()} isLoading={isSaving}
                  isDisabled={(currentFinanceSubscription?.status !== 'active' && currentTaxSubscription?.status !== 'active') || isSaving}
                  loadingText='Saving'>Save</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddClientModal;
